import axios from "axios";
import config from "@/config";
import store from "@/store/index.js"

const API_URL = config.apiUrl + config.apiUrlPrefix;

class AuthenticationService {

    getAuthorizedUser(token) {
        return axios({
            method: 'GET',
            url: `${API_URL}/authority`,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });
    }

    async login(data) {
        let result;
        try {
            result = await axios({
                method: 'POST',
                data: data,
                url: `${API_URL}/login`});
        } catch (error) {
            return null;
        }

        let token = result.data.access;
        let user = (await this.getAuthorizedUser(token)).data;
        user.access = token;

        localStorage.setItem('user', JSON.stringify(user));
        store.dispatch('setLoggedInUser', user);
        axios.defaults.headers.common['Authorization'] = "Bearer " + token; // for all requests
        return user;
    }

    logout() {
        axios.defaults.headers.common['Authorization'] = null;
        store.dispatch('setLoggedInUser', null);
        store.dispatch('resetVuex');
        localStorage.removeItem('user');
    }
}

export default new AuthenticationService();