import axios from "axios";
import config from "@/config";
import UtilService from "./UtilService";

const API_URL = config.apiUrl + config.apiUrlPrefix;

class ToolsService {

    getAllTools(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/tools`,
            params: data
        });
    }

    getTool(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/tools/${data.id}`,
            params: data
        });
    }

    getToolByCode(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/tools/code`,
            params: data

        });
    }

    getToolTimeline(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/tools/${data.id}/timeline`,
            params: data
        });
    }

    getAllActiveTools() {
        return axios.get(
            `${API_URL}/tools`,
        );
    }


    addTool(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/tools`,
            data: data,
        })
    }

    putTool(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/tools/${data.id}`,
            data: data,
        })
    }

    deleteTool(data) {
        return axios({
            method: 'DELETE',
            url: `${API_URL}/tools/${data.id}`,
            data: data,
        })
    }



    getAllToolTypes() {
        return axios.get(
            `${API_URL}/tools/types`,
        );
    }

    addToolType(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/tools/types`,
            data: data,
        })
    }

    putToolType(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/tools/types/${data.id}`,
            data: data,
        })
    }




    getAllWarehouses() {
        return axios.get(
            `${API_URL}/tools/warehouses`,
        );
    }

    addWarehouse(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/tools/warehouses`,
            data: data,
        })
    }

    putWarehouse(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/tools/warehouses/${data.id}`,
            data: data,
        })
    }



    getToolTransfer(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/tools/transfers`,
            params: data,
        })
    }
    createToolTransfer(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/tools/transfers`,
            data: data,
        })
    }

    toolTransferToStatusInTransfer(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/tools/transfers/${data.id}/to-in-transfer`,
            data: data,
        })
    }


    toolTransferToStatusTransferred(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/tools/transfers/${data.id}/to-transferred`,
            data: data,
        })
    }


    toolTransferToStatusAccepted(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/tools/transfers/${data.id}/to-accepted`,
            data: data,
        })
    }

    toolTransferToStatusDecline(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/tools/transfers/${data.id}/decline`,
            data: data,
        })
    }


    getAllToolsDamages(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/tools/damages`,
            params: data
        });
    }

    getToolDamages(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/tools/${data.tool}/damages`,
            params: data
        });
    }

    getToolDamage(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/tools/damages/${data.id}`,
            params: data
        });
    }

    confirmToolDamage(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/tools/damages/${data.id}/confirm`,
            data: data
        });
    }

    rejectToolDamage(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/tools/damages/${data.id}/reject`,
            data: data
        });
    }

    postToolDamage(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/tools/damages`,
            data: data
        });
    }


    getAllToolsServices(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/tools/services`,
            params: data
        });
    }

    getToolServices(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/tools/${data.tool}/services`,
            params: data
        });
    }

    getToolService(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/tools/services/${data.id}`,
            params: data
        });
    }

    putToolService(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/tools/services/${data.id}`,
            data: data
        });
    }

    postToolService(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/tools/services`,
            data: data
        });
    }

    getToolsNames() {
        return axios( {
            method: 'GET',
            url: `${API_URL}/tools/names`,
        })
    }
    
    getSubtypesNames() {
        return axios( {
            method: 'GET',
            url: `${API_URL}/tools/subtypes/names`,
        })
    }


    prepareToolTransfersData(data) {
        data.forEach(toolTransfer => {  
            toolTransfer.transfer_status_display = UtilService.translateTransferStatus(toolTransfer.transfer_status)


            if (toolTransfer.from_user) {
                toolTransfer.from = toolTransfer.from_user_obj.first_name + " " + toolTransfer.from_user_obj.last_name
            } else if (toolTransfer.from_warehouse) {
                toolTransfer.from = toolTransfer.from_warehouse_obj.name
            }

            if (toolTransfer.to_user) {
                toolTransfer.to = toolTransfer.to_user_obj.first_name + " " + toolTransfer.to_user_obj.last_name
            } else if (toolTransfer.to_warehouse) {
                toolTransfer.to = toolTransfer.to_warehouse_obj.name
            }
        });

        return data;
    }


    prepareToolsData(data) {
        data.forEach(tool => {  

            if (tool.worker) {
                tool.holder_display = tool.worker_obj.first_name + " " + tool.worker_obj.last_name
            } else if (tool.warehouse) {
                tool.holder_display = tool.warehouse_obj.name
            }

        });

        return data;
    }

    prepareToolsDamageData(data) {
        data.forEach(damage => {  

            if (damage.damage_status) {
                damage.damage_status_display = UtilService.translateToolDamageStatus(damage.damage_status)
            }

        });

        return data;
    }

    prepareToolsHistory(data) {
        let last_history = null;
        Object.values(data).slice().reverse().forEach(group => {
            group.slice().reverse().forEach(x => {
                if (x.timeline_type === 'HISTORY') {
                    x.last = last_history;
                    last_history = x;
                }
            })
        })

    }

}

export default new ToolsService();