<template>
<div v-if="user" class="container">

    <timeline-worker-sumary />

    <div class="row g-4 mb-3" id="cards">

        <template v-if="user.role === 'ADMIN'">
            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/radnici.png" class="card-img-top" alt="Radnici">
                    <div class="card-body">
                        <h4 class="card-title">Radnici</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'EmployeesCardsPage'}"></router-link>
                </div>
            </div>
            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/radni_nalog.png" class="card-img-top" alt="Radni nalozi">
                    <div class="card-body">
                        <h4 class="card-title">Radni nalozi</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'WorkOrders'}"></router-link>
                </div>
            </div>


            <div class="col-6 col-md-4 position-relative">
                <div class="card">
                    <img src="../assets/upucivanje.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h4 class="card-title">Uputi radnike</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'JobAssignment'}"></router-link>
                </div>
            </div>

                
            <div class="col-6 col-md-4 position-relative">
                    <span v-if="jobAssignments.length > 0" class="position-absolute translate-middle badge rounded-pill bg-danger notification-badge">{{ jobAssignments.length }}</span>
                <div class="card">
                    <img src="../assets/upucivanje.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h4 class="card-title">Poslovi</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'worker-jobs'}"></router-link>
                </div>
            </div>

            <!-- <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/poslovi.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h4 class="card-title">Aktivni Poslovi</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'ActiveAssignments'}"></router-link>
                </div>
            </div> -->
            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/poslovne_jedinice.png" class="card-img-top" alt="Poslovne jedinice">
                    <div class="card-body">
                        <h4 class="card-title">Poslovne jedinice</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'AllPositionUnits'}"></router-link>
                </div>
            </div>

            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/working-hours.png" class="card-img-top" alt="Evidencija radnika">
                    <div class="card-body">
                        <h4 class="card-title">Evidencija radnika</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'work-attendance'}"></router-link>
                </div>
            </div>

            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/calendar-cover.png" class="card-img-top" alt="Radni dani">
                    <div class="card-body">
                        <h4 class="card-title">Radni dani</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'calendar-day-status'}"></router-link>
                </div>
            </div>

            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/bus.png" class="card-img-top" alt="Prevoz">
                    <div class="card-body">
                        <h4 class="card-title">Prevoz</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'transport-vehicles'}"></router-link>
                </div>
            </div>

            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/tool-box.png" class="card-img-top" alt="Alat">
                    <div class="card-body">
                        <h4 class="card-title">Alat</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'tools'}"></router-link>
                </div>
            </div>


            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/hamburger.png" class="card-img-top" alt="Alat">
                    <div class="card-body">
                        <h4 class="card-title">Ketering</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'catering'}"></router-link>
                </div>
            </div>

            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/settings-cover.png" class="card-img-top" alt="Alat">
                    <div class="card-body">
                        <h4 class="card-title">Podešavanja</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'settings'}"></router-link>
                </div>
            </div>
            
        </template>


        <template v-if="user.role === 'MANAGER'">
            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/upucivanje.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h4 class="card-title">Poslovi</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'worker-jobs'}"></router-link>
                </div>
            </div>
            <!-- <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/radnici.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h4 class="card-title">Radnici</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'EmployeesCardsPage'}"></router-link>
                </div>
            </div> -->


            <div class="col-6 col-md-4 position-relative">
                <div class="card">
                    <img src="../assets/upucivanje.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h4 class="card-title">Uputi radnike</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'JobAssignment'}"></router-link>
                </div>
            </div>

            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/radni_nalog.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h4 class="card-title">Radni nalozi</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'WorkOrders'}"></router-link>
                </div>
            </div>
            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/poslovi.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h4 class="card-title">Aktivni Poslovi</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'ActiveAssignments'}"></router-link>
                </div>
            </div>
            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/working-hours.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h4 class="card-title">Evidencija radnika</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'work-attendance'}"></router-link>
                </div>
            </div>


        
            <div class="col-6 col-md-4">
                <div class="card h-100 upucivanje">
                    <img src="../assets/upucivanje-radnika.png" class="card-img-top" alt="Svi radnici">
                    <div class="card-body">
                        <h4 class="card-title">Svi radnici</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'EmployeesAssignment'}"></router-link>
                </div>
            </div>

            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/bus.png" class="card-img-top" alt="Prevoz">
                    <div class="card-body">
                        <h4 class="card-title">Prevoz</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'transport-vehicles'}"></router-link>
                </div>
            </div>

            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/calendar-cover.png" class="card-img-top" alt="Radni dani">
                    <div class="card-body">
                        <h4 class="card-title">Radni dani</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'calendar-day-status'}"></router-link>
                </div>
            </div>
        
            <div class="col-6 col-md-4">
                <div class="card h-100 upucivanje">
                    <img src="../assets/upucivanje-radnika.png" class="card-img-top" alt="Raspored radnika">
                    <div class="card-body">
                        <h4 class="card-title">Raspored radnika</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'EmployeesAssignmentAndTrip'}"></router-link>
                </div>
            </div>
                

            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/tool-box.png" class="card-img-top" alt="Alat">
                    <div class="card-body">
                        <h4 class="card-title">Alat</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'AllTools'}"></router-link>
                </div>
            </div>

            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/hamburger.png" class="card-img-top" alt="Alat">
                    <div class="card-body">
                        <h4 class="card-title">Ketering</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'catering'}"></router-link>
                </div>
            </div>
            
        </template>


        <template v-if="user.role === 'WORKER'">
            <div class="col-6 col-md-4">
                <div class="card">
                    <img src="../assets/upucivanje.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h4 class="card-title">Poslovi</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'worker-jobs'}"></router-link>
                </div>
            </div>

            <div v-if="workingHourPerm" class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/working-hours.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h4 class="card-title">Evidencija radnika</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'work-attendance'}"></router-link>
                </div>
            </div>

            <div v-if="workingHourPerm" class="col-6 col-md-4 position-relative">
                <div class="card">
                    <img src="../assets/upucivanje.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h4 class="card-title">Uputi radnike</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'JobAssignment'}"></router-link>
                </div>
            </div>

            <div v-if="userTodayTrips && userTodayTrips.length > 0" class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/bus.png" class="card-img-top" alt="Prevoz">
                    <div class="card-body">
                        <h4 class="card-title">Prevoz</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'transport-vehicles'}"></router-link>
                </div>
            </div>
            

            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/abs_calendar.png" class="card-img-top" alt="Prevoz">
                    <div class="card-body">
                        <h4 class="card-title">Odsustva</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'EmployeeAbsences', params:{'username': user.username}}"></router-link>
                </div>
            </div>

            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/calendar-cover.png" class="card-img-top" alt="Radni dani">
                    <div class="card-body">
                        <h4 class="card-title">Radni dani</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'calendar-day-status'}"></router-link>
                </div>
            </div>



            <div v-if="workingHourPerm" class="col-6 col-md-4">
                <div class="card h-100 upucivanje">
                    <img src="../assets/upucivanje-radnika.png" class="card-img-top" alt="Raspored radnika">
                    <div class="card-body">
                        <h4 class="card-title">Raspored radnika</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'EmployeesAssignmentAndTrip'}"></router-link>
                </div>
            </div>

            <div class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/tool-box.png" class="card-img-top" alt="Alat">
                    <div class="card-body">
                        <h4 class="card-title">Alat</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'tools'}"></router-link>
                </div>
            </div>


            <div v-if="workingHourPerm" class="col-6 col-md-4">
                <div class="card h-100">
                    <img src="../assets/hamburger.png" class="card-img-top" alt="Alat">
                    <div class="card-body">
                        <h4 class="card-title">Ketering</h4>
                    </div>
                    <router-link class="stretched-link" :to="{ name: 'catering'}"></router-link>
                </div>
            </div>
            
        </template>

    </div>
</div>
</template>

<script>
    import {mapState} from 'vuex'
    import TimelineWorkerSumary from '@/components/TimelineWorkerSumary.vue'
    import JobAssignmentService from '@/service/JobAssignmentService.js';


    export default {
        components: { TimelineWorkerSumary },

        data() {
            return {
                jobAssignments: []
            }
        },

        computed: {
            ...mapState([
                'user',
                'userAssignment',
                'userTodayTrips',
            ]),

            workingHourPerm() {
                if (this.user.role === "MANAGER" || this.user.role === "ADMIN" || this.userAssignment?.pwo?.pwo_role == 1)
                    return true;
                return false;
            }
        },

        methods: {
            async loadJobAssignments() {
                let payload = {
                    url: {
                        employeeId: this.user.id
                    }
                }

                try {
                    let response = await JobAssignmentService.getJobAssignments(payload) 
                    this.jobAssignments = response.data
                } catch(error) {
                    console.log(error);
                }

            }
        },

        created() {
            this.loadJobAssignments()
        }
    }
</script>

<style scoped>

.container {
    min-height: 73vh;
}

#cards {
    margin-top: 30px;
}

.h4 {
    font-style: bold;
}

.card-img-top {
    width: 100%;
    height: 12vw;
    min-height: 150px;
    object-fit: cover;
}

#cards .card {
    min-height: 100px;
}

#cards .card-body {
    background: #ffffffa7;
    position: absolute;
    bottom: 0;
    width: 100%;
}

#cards .card:hover .card-body {
    background: #ffffffef;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.notification-badge {
    font-size: 2.5em;
    z-index: 1000;
    left: 95%;
    top: 10px;
}

</style>