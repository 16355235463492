<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> Samostalna evidencija </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body" v-if="currentWO && currentWO.pwo && currentWO.pwo.work_order_obj && currentWO.pwo.work_order_obj.id">
                    <!-- <h2 v-if="currentWO">{{currentWO.pwo.work_order_obj.name}}</h2> -->
                    <div v-if="arrive">
                        <p>Evidentiraj izlazak u {{formatTime(confirmedTime)}} ({{formatTime(currentTime)}})</p>

                    </div>
                    <div v-else>
                        <p v-if="isFirstArrival">Evidentiraj dolazak u {{formatTime(confirmedTime)}} ({{formatTime(currentTime)}})</p>
                        <p v-else>Evidentiraj novi dolazak u {{formatTime(confirmedTime)}} ({{formatTime(currentTime)}})</p>
                    </div>


                    <table v-if="userWorkAttendance" width="2000px" class="table timesheet-overview-table">
                        <tbody>
                            <tr>
                                <td>Radni Nalog</td>
                                <td>Početak</td>
                                <td>Kraj</td>
                            </tr>
                            <template v-if="userWorkAttendance.timesheet">
                                <tr v-for="timesheet in userWorkAttendance.timesheet" :key="timesheet.id">
                                    <td>{{timesheet.work_order_obj.name}}</td>

                                    <td>{{formatTime(convertMoment(timesheet.time_start_confirmed, "HH:mm:ss"))}}</td>
                                    <td v-if="timesheet.time_end">
                                        {{formatTime(convertMoment(timesheet.time_end_confirmed, "HH:mm:ss"))}}
                                    </td>
                                    <td v-else class="self-sign-table-cell">
                                        <u class="self-sign-table-cell">{{formatTime(confirmedTime)}}</u>
                                    </td>
                                </tr>
                            </template>
                                <tr v-if="!arrive" class="self-sign-table-cell">
                                    <td>{{currentWO.pwo.work_order_obj.name}}</td>
                                    <td>{{formatTime(confirmedTime)}}</td>
                                </tr>
                            <tr col-span="3">Ukupno: {{totalHours}}h</tr>
                        </tbody>
                    </table>


                    <hr>
                    <div v-if="!arrive">
                        <h5>Obrok</h5>
                        <div class="catering-select row">

                            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" class="btn-check" name="catering-type" id="self-catering" autocomplete="off" v-model="catering" value="OWN">
                                <label class="btn btn-large btn-outline-primary col-6" for="self-catering">Samostalni obrok</label>

                                <input type="radio" class="btn-check" name="catering-type" id="restaurant" autocomplete="off" :disabled="!isCateringAvailable" v-model="catering" value="COMPANY">
                                <label class="btn btn-large btn-outline-primary col-6" for="restaurant">Ketering<small v-if="work_order_catering && isCateringAvailable"> ({{ work_order_catering.name }})</small><small v-else-if="!isCateringAvailable"> (Prijava do {{ assignEndTime.slice(0,5) }})</small></label>
                            </div>


                            <!-- <label class="form-check-label list-group-item" for="include-catering">
                                <input v-model="includeCatering" class="form-check-input" type="checkbox" value="" id="include-catering">
                                    Prijavi se za obrok
                            </label>
                            <div v-if="includeCatering" class="form-floating catering-service-select">
                                <select class="form-select" v-model="currentWO.pwo.work_order_obj.catering_service" id="catering-service" aria-label="Izbor restorana">
                                    <option :value="null">Samostalno</option>
                                    <option v-for="cateringService in allCateringServices" :value="cateringService.id" :key="cateringService.id">{{cateringService.name}}</option>
                                </select>
                                <label for="catering-service">Restoran</label>

                            </div> -->
                        </div>
                    </div>
                    <div v-else-if="isOvertimeCandidate">
                        <h5>Prekovremeni</h5>
                        <label class="form-check-label list-group-item" for="include-catering">
                                <input v-model="isOvertime" class="form-check-input" type="checkbox" value="" id="include-catering">
                                    Evidentiraj prekovremeni rad
                            </label>
                    </div>
                    <!-- <h3>Hrana</h3> -->

                    
                </div>

                <div class="modal-footer">
                    <button @click="$emit('close')" type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button @click="confirmSign" :disabled="loading" type="button" class="btn btn-primary" data-bs-dismiss="modal"> Potvrdi </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UtilService from '@/service/UtilService.js';
    import {mapState} from 'vuex'
    import { fromLonLat } from "ol/proj";
    import LineString from "ol/geom/LineString";
    import {getLength} from 'ol/sphere';
    import EmployeesService from '@/service/EmployeesService.js';
    import CateringService from '@/service/CateringService.js';
    import moment from "moment";

    export default {



        props: {
            modalId: String,
        },

        data() {
            return {
                currentTime: this.getMoment(),
                attempt: 0,
                loading: false,
                includeCatering: false,
                isOvertime: false,
                endOfDayTime: moment("17:00:00", "HH:mm:ss"),
                catering: "OWN",
                work_order_catering: null,
            }
        },

        computed: {
            ...mapState([
                'userAssignment',
                'userWorkAttendance',
                'user',
                'allCateringServices',
                'settings'
            ]),

            totalHours() {
                let hours = this.sumTimesheetHours(this?.userWorkAttendance?.timesheet)
                let ts = this.getLastTimesheet(this.userWorkAttendance)
                
                if (ts?.time_start_confirmed) {
                    let end_time = ts?.time_end_confirmed || this.confirmedTime
    
                    let a = moment(ts.time_start_confirmed, "HH:mm:ss")
                    let b = moment(end_time, "HH:mm:ss")
    
                    let duration = moment.duration(b.diff(a)).asHours()
                    if (duration > 0) hours += duration
                }
                


                if (this.isOvertime) {
                    return hours
                } else {
                    return Math.min(hours, 10)
                }
            },

            isOvertimeCandidate() {
                return this.sumTimesheetHours(this.userWorkAttendance.timesheet, true) > 10 && this.isPastWorkingHours
            },

            isPastWorkingHours() {
                if (this.roundedTime > this.endOfDayTime) return true
                return false
            },

            currentWO() {
                if (!this.userAssignment) return undefined;
                if (this.userAssignment?.day_status?.working == false) return null
                if (this.userAssignment?.absence) return null
                return this.userAssignment;
            },

            arrive() {
                if (!(this.userWorkAttendance?.timesheet?.length > 0)) return false
                if (this.userWorkAttendance?.timesheet?.at(-1).time_end !== null) return false;
                return true
            },
            
            isFirstArrival() {
                return !(this.userWorkAttendance?.timesheet?.length > 0)
            },

            roundedTime() {
                if (!this.currentTime) return this.getMoment();
                if (this.arrive) {
                    return this.roundLower30min(this.currentTime)
                } else {
                    return this.roundUpper30min(this.currentTime)
                }
            },

            confirmedTime() {
                if (!this.currentTime) return this.getMoment();
                if (this.arrive) {
                    let rounded = this.roundLower30min(this.currentTime)


                    if (!this.isOvertime) {
                        let max_limit = this.endOfDayTime
                        if (rounded > max_limit) rounded = max_limit
                    }

                    let lastTimesheet = this.getLastTimesheet(this.userWorkAttendance)
                    let limit = moment(lastTimesheet.time_start_confirmed, "HH:mm:ss")

                    if (lastTimesheet && rounded < limit) {
                        rounded = limit
                    }

                    return rounded
                } else {
                    let rounded = this.roundUpper30min(this.currentTime)
                    return rounded
                }
            },


            assignEndTime() {
                if (!this.settings) return ""

                let assign_end_time = this.settings.find(a => a.identifier == "catering__assign_end_time").value
                return assign_end_time
            },

            isCateringAvailable() {
                if (!this.settings) return false


                let assign_end_time = this.settings.find(a => a.identifier == "catering__assign_end_time").value

                let current_time = (new Date()).toLocaleTimeString('en-US', { hourCycle: 'h23' }); 
                if (assign_end_time >= current_time) return true
                return false
            }

        },


        methods: {
            sumTimesheetHours : UtilService.sumTimesheetHours,
            convertMoment : UtilService.convertMoment,
            formatTime : UtilService.formatTime,
            userFullName : UtilService.userFullName,
            getMoment: UtilService.getMoment,
            roundUpper30min: UtilService.roundUpper30min,
            roundLower30min: UtilService.roundLower30min,

            getLastTimesheet(userWorkAttendance) {
                let timesheet = userWorkAttendance?.timesheet?.find(x => x.time_end == null);
                return timesheet
            },


            setUpListener() {
                let myModalEl = document.getElementById(this.modalId)
                myModalEl.addEventListener('show.bs.modal', () => {
                    this.initData();
                })
            },

            confirmSign() {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(this.doSelfAttentance, this.geoDeclined);
                } else {
                    alert("Nije moguce izvrsiti samostalnu evidencijju.");
                }
            },

            geoDeclined(error) {
                console.log(error);
                this.loading = false;
                if (error.code == error.PERMISSION_DENIED)
                    alert("NEUSPEŠNA EVIDENCIJA! Morate dozvoliti lokaciju, Kontaktirajte administratora za uputstvo");
                else if (error.code == error.POSITION_UNAVAILABLE) 
                    alert("NEUSPEŠNA EVIDENCIJA! Nema informacija o lokaciji, Kontaktirajte administratora za evidenciju");
                else
                    alert("NEUSPEŠNA EVIDENCIJA! Neuspešna evidencija, kotaktiraje administratora za evidenciju");
            },

            doSelfAttentance(position) {
                this.longitude = position.coords.longitude;
                this.latitude = position.coords.latitude;

                let [valid, radius] = this.checkLocation();
                // let [valid, radius] = [true, 10];

                if (valid || (radius < 250 && confirm('Evidentiraj se'))) {
                    this.selfWorkAttendanceSign(radius);
                }
                else {
                    alert("Udaljeni ste " + radius + "m od zone za samostalnu evidenciju, približite se na minimum 200m.");
                    this.attempt++;
                    this.loading = false;
                }

            },

            checkLocation() {
                if (this.currentWO.pwo.work_order_obj.location_longitude && this.currentWO.pwo.work_order_obj.location_latitude, this.currentWO.pwo.work_order_obj.location_radius) {
                    let lonlatCurrent = [this.longitude, this.latitude];
                    let lonlatDestination = [this.currentWO.pwo.work_order_obj.location_longitude, this.currentWO.pwo.work_order_obj.location_latitude];
                    let radius = this.distanceBetweenPoints(lonlatCurrent, lonlatDestination);
                    let radius_m = Math.round(this.realDistanceBetweenPoints(lonlatCurrent, lonlatDestination))
                    if (radius > this.currentWO.pwo.work_order_obj.location_radius) return [false, radius_m - this.currentWO.pwo.work_order_obj.location_radius];
                    return [true, 0];
                }
                return [true, 0];
            },

            distanceBetweenPoints(lonlat1, lonlat2){
                let a = fromLonLat(lonlat1)
                let b = fromLonLat(lonlat2)
                var line = new LineString([a, b]);

                return Math.round(line.getLength() * 100) / 100;
            },


            realDistanceBetweenPoints(lonlat1, lonlat2){
                let a = fromLonLat(lonlat1)
                let b = fromLonLat(lonlat2)
                var line = new LineString([a, b]);

                return getLength(line)
            },

            getUserWorkAttendance() {
                this.$store.dispatch('loadUserWorkAttendance');
            },

            async selfWorkAttendanceSign(radius=0) {
                let data = {
                    "longitude": this.longitude,
                    "latitude": this.latitude,
                    "outside_distance": radius,
                    "isOvertime": this.isOvertime
                }

                let result;
                try {
                    if (!this.arrive) {
                        result = await EmployeesService.selfWorkAttendaceStart(data);
                        this.addCatering();
                    }
                    else result = await EmployeesService.selfWorkAttendaceEnd(data);
    
                    this.work_attendance = result.data
                    
                } catch (error) {
                    console.log(error);
                    alert("Vec ste evidentiali dolazak")
                }
                
                this.getUserWorkAttendance();
                this.loading = false;
            },

            async addCatering() {
                let data = {
                    employee_id: this.user.id,
                    date: UtilService.getMoment().format("YYYY-MM-DD"),
                    time: this.formatTime(this.currentTime),
                    work_order_id: this.currentWO.pwo.work_order,
                    catering_service_id: null,
                    provided_by: this.catering
                }

                if (this.work_order_catering && this.catering == "COMPANY")
                    data.catering_service_id = this.work_order_catering.id

                CateringService.addCatering(data).then(result => {
                    console.log(result);
                    this.$toasted.show("+ obrok za radnika")   
                })
            },

            async loadWorkOrderCattering() {
                try {
                    let result = await CateringService.getCurrentWorkOrderCateringService();
                    this.work_order_catering = result.data
                } catch (error) {
                    console.log(error);
                    this.$toasted.show("Neuspešno slanje", { 
                        type: 'error',
                        theme: "toasted-primary", 
                        position: "top-center", 
                        duration : 3000
                    });
                }
            },
            
            async initData() {
                this.loading = true
                if (!this.settings) this.$store.dispatch('loadSettings');
                await this.loadWorkOrderCattering();
                if (this.work_order_catering && this.isCateringAvailable) {
                    this.catering = "COMPANY"
                }
                this.loading = false

            }

        },

        mounted() {
            this.setUpListener();
        }

    }
</script>

<style>
p {
    font-size: 140%;
}
</style>

<style scoped>
    .self-sign-table-cell {
        background: orange;
        font-weight: bold;
    }

    .catering-select {
        font-size: 1.8em
    }

    .catering-select > input {
        margin-right: 10px;
    }

    .catering-service-select > label {
        font-size: 1rem;
    }
</style>