import Vue from 'vue'
import Vuex from 'vuex'
import WorkOrderService from '@/service/WorkOrderService.js'
import WorkLocation from '@/service/WorkLocationService.js'
import EmployeesService from '@/service/EmployeesService.js'
import AuthenticationService from '../service/AuthenticationService'
import JobAssignmentService from '@/service/JobAssignmentService.js'
import TypeService from '@/service/TypeService.js'
import WorkPositionService from '@/service/WorkPositionService'
import NormativeService from '@/service/NormativeService'
import UtilService from '../service/UtilService'
import WorkLocationService from '../service/WorkLocationService'
import TransportService from '@/service/TransportService.js'
import AbsencesService from '@/service/AbsencesService.js'
import ToolsService from '@/service/ToolsService.js'
import CateringService from '@/service/CateringService.js'
import SettingsService from '@/service/SettingsService.js'


Vue.use(Vuex)

const store = new Vuex.Store({

    state: {
        user: null,
        role: undefined,
        isAdmin: false,
        allWorkOrders: [],

        allEmployees: [],
        allEmployeesStatus: null,
        allEmployeesMessage: "",

        allActiveUsers: [],

        allWorkers: [],
        allWorkersStatus: null,
        allWorkersMessage: "",

        workOrderEmployees: [],
        workOrderEmployeesStatus: null,
        workOrderEmployeesMessage: "",

        assignableWorkLocations: [],
        workLocationPositions: [],

        jobAssignmentFormStatus: "",
        jobAssignmentError: null,

        workerJobs: [],
        workerJobsStatus: null,
        workerJobsMessage: "",
        
        workLocationsStatus: "",
        workLocationsError: null,
        workLocations: [],

        workPositionsStatus: "",
        workPositionsError: null,
        workPositions: null,
        positionGroups: [],


        position_units: {},
        positionUnits: [],

        locationTypes: {},
        locationTypesStatus: "",
        locationTypesMessage: null,

        worksInJob: [],
        worksInJobStatus: null,
        worksInJobMessage: "",

        workOrder: null,
        workOrderStatus: "",
        workOrderMessage: "",
        
        allJobs: [],
        allJobsStatus: null,
        allJobsMessage: "",

        workOrderActiveJobs: [],
        workOrderActiveJobsStatus: null,
        workOrderActiveJobsMessage: "",

        workPositionJobs: [],
        workPositionJobsStatus: null,
        workPositionJobsMessage: "",

        loadedWorks: [],
        loadedWorksStatus: "",
        loadedWorksMessage: "",

        locationPositionWorks: [],
        locationPositionWorksStatus: "",
        locationPositionWorksMessage: "",

        selectedWorkLocation: [],
        selectedWorkLocationStatus: "",
        selectedWorkLocationMessage: "",

        selectedWorkPosition: [],
        selectedWorkPositionStatus: "",
        selectedWorkPositionMessage: "",

        selectedNormative: [],
        selectedNormativeStatus: "",
        selectedNormativeMessage: "",

        preventTabClose: false,
        preventTabCloseMessage: "Da li ste sigurni da želite da zatvorite stranicu",

        userAssignment: undefined,
        userWorkAttendance: undefined,
        userSettings: {},
        userCache: {},

        transportVehicles: null,
        transportVehicle: null,
        vehicleTrips: null,
        vehicleFuels: null,
        vehicleService: null,

        gasStations: undefined,

        userTrips: undefined,
        userTodayTrips: undefined,

        vehiclesStats: undefined,
        vehiclesFuelStats: undefined,

        absenceRequests: undefined,

        serviceItemNames: [],

        workAttendanceEmployees: [],

        toolTypes: [],
        warehouses: [],
        toolsNames: [],
        toolsSubtypesNames: [],
        awaitingToolTransfers: [],
        toolProfile: undefined,

        allCateringServices: [],

        settings: null,
    },

    getters: {
        role(state) {
            return state.user.role;
        },
        
        preventTabClose(state) {
            return state.preventTabClose;
        },
        
        preventTabCloseMessage(state) {
            return state.preventTabCloseMessage;
        },
        
        isLoggedIn(state) {
            if (state.user) return true;
            return false;
        },

        isAdmin(state) {
            if (state.user?.role === 'ADMIN') return true;
            return false;
        },

        isManager(state) {
            if (state.user?.role === "MANAGER" || state.user?.role === "ADMIN" || state.userAssignment?.pwo?.pwo_role == 1)
                return true;
            return false;
        },

    },

    mutations: {
        SET_USER (state, user) {
            state.user = user;
            state.role = user?.role;
            state.isAdmin = user?.role == "ADMIN";
        },
        SET_WORK_ORDERS (state, workOrders) {
            state.allWorkOrders = workOrders
        },

        // Employees list
        SET_ALL_EMPLOYEES (state, workers) {
            state.allEmployees = workers
        },
        SET_ALL_EMPLOYEES_STATUS (state, status=null) {
            state.allEmployeesStatus = status;
        },
        SET_ALL_EMPLOYEES_MESSAGE (state, message="") {
            state.allEmployeesMessage = message;
        },


        // Workers list
        SET_ALL_WORKERS (state, workers) {
            state.allWorkers = workers
        },
        SET_ALL_WORKERS_STATUS (state, status=null) {
            state.allWorkersStatus = status;
        },
        SET_ALL_WORKERS_MESSAGE (state, message="") {
            state.allWorkersMessage = message;
        },
        
        // WorkOrder Workers list
        SET_WO_EMPLOYEES (state, workers) {
            state.workOrderEmployees = workers
        },
        SET_WO_EMPLOYEES_STATUS (state, status=null) {
            state.workOrderEmployeesStatus = status;
        },
        SET_WO_EMPLOYEES_MESSAGE (state, message="") {
            state.workOrderEmployeesMessage = message;
        },


        SET_POSITION_GROUPS (state, positionGroups) {
            state.positionGroups = positionGroups
        },
        SET_WORK_LOCATIONS (state, workLocations) {
            state.workLocations = workLocations
        },
        SET_ASSIGNABLE_WORK_LOCATION (state, workLocations) {
            state.assignableWorkLocations = workLocations
        },
        SET_WORK_LOCATION_POSITION (state, workLocationPositions) {
            state.workLocationPositions = workLocationPositions
        },
        // JobAssignment
        SET_JOB_ASSIGNMENT_SENDING (state) {
            state.jobAssignmentFormStatus = "SENDING";
        },
        SET_JOB_ASSIGNMENT_SENT (state) {
            state.jobAssignmentFormStatus = "SUCCESS";
        },
        SET_JOB_ASSIGNMENT_ERROR (state, error) {
            state.jobAssignmentFormStatus = "ERROR";
            state.jobAssignmentError = error.data;
        },

        // Worker Jobs 
        SET_WORKER_JOBS (state, workerJobs) {
            UtilService.prepareWorkerJobs(workerJobs);
            state.workerJobs = workerJobs;
        },
        
        SET_WORKER_JOBS_STATUS (state, status=null) {
            state.workerJobsStatus = status;
        },

        SET_WORKER_JOBS_MESSAGE (state, message="") {
            state.workerJobsMessage = message;
        },

        CLEAR_WORKER_JOBS (state) {
            state.workerJobs = [];
        },

        // Position unit
        SET_POSITION_UNITS (state, position_units) {
            state.positionUnits = position_units;
            state.position_units = UtilService.listToMap(position_units);
        },

        // Work Positions
        SET_WORK_POSITIONS (state, workPositions) {
            state.workPositions = workPositions;
        },

        //Work Order
        
        // Work Positions
        SET_WORK_ORDER(state, workOrder) {
            state.workOrder = workOrder;
        },
        SET_WORK_ORDER_STATUS (state, status=null) {
            state.workOrderStatus = status;
        },
        SET_WORK_ORDER_MESSAGE (state, message="") {
            state.workOrderMessage = message;
        },

        // Types
        SET_LOCATION_TYPES_LOADING(state, status="LOADING") {
            state.locationTypesStatus = status;
        },
        

        SET_LOCATION_TYPES(state, locationTypes){
            state.locationTypesStatus = "SUCCESS";
            state.locationTypes = locationTypes;
        },

        SET_LOCATION_TYPES_MESSAGE(state, msg="") {
            state.locationTypesMessage = msg;
        },

        // Works In job
        
        SET_WORKS_IN_JOB(state, worksInJobStatus){
            state.worksInJobStatus = worksInJobStatus;
        },

        SET_WORKS_IN_JOB_STATUS(state, status="LOADING") {
            state.worksInJobStatus = status;
        },

        SET_WORKS_IN_JOB_MESSAGE(state, message="") {
            state.worksInJobMessage = message;
        },


        // All Jobs 
        SET_ALL_JOBS (state, allJobs) {
            UtilService.prepareWorkerJobs(allJobs);
            state.allJobs = allJobs;
        },
        
        SET_ALL_JOBS_STATUS (state, status=null) {
            state.allJobsStatus = status;
        },

        SET_ALL_JOBS_MESSAGE (state, message="") {
            state.allJobsMessage = message;
        },

        CLEAR_ALL_JOBS (state) {
            state.allJobs = [];
        },       
        

        // WorkOrder Active jobs 
        SET_WO_ACTIVE_JOBS (state, workOrderActiveJobs) {
           // UtilService.prepareWorkerJobs(workOrderActiveJobs);
            state.workOrderActiveJobs = workOrderActiveJobs;
        },
        
        SET_WO_ACTIVE_JOBS_STATUS (state, status=null) {
            state.workOrderActiveJobsStatus = status;
        },

        SET_WO_ACTIVE_JOBS_MESSAGE (state, message="") {
            state.workOrderActiveJobsMessage = message;
        },

        CLEAR_WO_ACTIVE_JOBS (state) {
            state.workOrderActiveJobs = null;
        },       
        

        // WorkPosition Jobs 
        SET_WP_JOBS (state, workPositionJobs) {
         //   UtilService.prepareWorkerJobs(workPositionsJobs);
            state.workPositionJobs = workPositionJobs;
        },
        
        SET_WP_JOBS_STATUS (state, status=null) {
            state.workPositionJobsStatus = status;
        },

        SET_WP_JOBS_MESSAGE (state, message="") {
            state.workPositionJobsMessage = message;
        },

        CLEAR_WP_JOBS (state) {
            state.workPositionJobs = null;
        },    

        
        // Loaded Works
        SET_WORKS (state, loadedWorks) {
        //   UtilService.prepareWorkerJobs(workPositionsJobs);
            state.loadedWorks = loadedWorks;
        },
        
        SET_WORKS_STATUS (state, status=null) {
            state.loadedWorksStatus = status;
        },

        SET_WORKS_MESSAGE (state, message="") {
            state.loadedWorksMessage = message;
        },

        CLEAR_WORKS (state) {
            state.loadedWorks = null;
        },

    
        // Modal location position Works history
        SET_LOC_POS_WORKS (state, locationPositionWorks) {
        //   UtilService.prepareWorkerJobs(workPositionsJobs);
            state.locationPositionWorks = locationPositionWorks;
        },
        
        SET_LOC_POS_WORKS_STATUS (state, status=null) {
            state.locationPositionWorksStatus = status;
        },

        SET_LOC_POS_WORKS_MESSAGE (state, message="") {
            state.locationPositionWorksMessage = message;
        },

        CLEAR_LOC_POS_WORKS (state) {
            state.locationPositionWorks = null;
        },  

    
        // Modal selected WP
        SET_SELECTED_WP (state, selectedWorkPosition) {
        //   UtilService.prepareWorkerJobs(workPositionsJobs);
            state.selectedWorkPosition = selectedWorkPosition;
        },
        
        SET_SELECTED_WP_STATUS (state, status=null) {
            state.selectedWorkPositionStatus = status;
        },

        SET_SELECTED_WP_MESSAGE (state, message="") {
            state.selectedWorkPositionMessage = message;
        },

        CLEAR_SELECTED_WP (state) {
            state.selectedWorkPosition = null;
        },    

    
        // Modal selected wl
        SET_SELECTED_WL (state, selectedWorkLocation) {
        //   UtilService.prepareWorkerJobs(workPositionsJobs);
            state.selectedWorkLocation = selectedWorkLocation;
        },
        
        SET_SELECTED_WL_STATUS (state, status=null) {
            state.selectedWorkLocationStatus = status;
        },

        SET_SELECTED_WL_MESSAGE (state, message="") {
            state.selectedWorkLocationMessage = message;
        },

        CLEAR_SELECTED_WL (state) {
            state.selectedWorkLocation = null;
        },


        // Modal selected wl
        SET_SELECTED_NORM (state, selectedNormative) {
        //   UtilService.prepareWorkerJobs(workPositionsJobs);
            state.selectedNormative = selectedNormative;
        },
        
        SET_SELECTED_NORM_STATUS (state, status=null) {
            state.selectedNormativeStatus = status;
        },

        SET_SELECTED_NORM_MESSAGE (state, message="") {
            state.selectedNormativeMessage = message;
        },

        CLEAR_SELECTED_NORM (state) {
            state.selectedNormative = null;
        },

        
        ENABLE_CLOSE_PROMPT (state, message = "Da li ste sigurni da želite da zatvorite stranicu") {
            state.preventTabClose = true;
            state.preventTabCloseMessage = message;
            window.onbeforeunload = confirmExit;
            function confirmExit() {
                return state.preventTabCloseMessage;
            }
        },
        
        DISABLE_CLOSE_PROMPT (state) {
            state.preventTabClose = false;
            window.onbeforeunload = undefined;
        },

        SET_USER_ASSIGNMENT(state, pwo){
            state.userAssignment = pwo;
        },

        SET_USER_WORK_ATTENDANCE(state, wa){
            state.userWorkAttendance = wa;
        },

        SET_USER_SETTINGS(state, data) {
            state.userSettings = data
        },

        SET_USER_CACHE(state, data) {
            state.userCache = data
        },

        UPDATE_USER_SETTINGS(state, {path, key, data}) {
            let statePath = state.userSettings;
            if (typeof state.userSettings !== 'object') state.userSettings = {};


            path.forEach(object => {
                statePath[object] = statePath[object] || {};
                if (typeof statePath[object] !== 'object') statePath[object] = {};
                statePath = statePath[object];

            });
            
            statePath[key] = data;

            localStorage.setItem('user-settings', JSON.stringify(state.userSettings));
        },

        UPDATE_USER_CACHE(state, {path, key, data}) {
            let statePath = state.userCache;
            if (typeof state.userCache !== 'object') state.userCache = {};


            path.forEach(object => {
                statePath[object] = statePath[object] || {};
                if (typeof statePath[object] !== 'object') statePath[object] = {};
                statePath = statePath[object];

            });
            
            statePath[key] = data;

            localStorage.setItem('user-cache', JSON.stringify(state.userCache));
        },

        SET_TRANSPORT_VEHICLES(state, transports) {
            state.transportVehicles = transports;
        },

        SET_GAS_STATION(state, gasStation) {
            state.gasStations = gasStation;
        },

        SET_TRANSPORT_VEHICLE(state, transport) {
            state.transportVehicle = transport;
        },

        SET_VEHICLE_FUEL(state, fuel) {
            state.vehicleFuels = fuel;
        },

        SET_VEHICLE_TRIPS(state, trips) {
            state.vehicleTrips = trips;
        },

        RESET_STORE(state) {
            state.userAssignment = null;
        },

        SET_VEHICLE_SERVICE(state, service) {
            state.vehicleService = service;
        },
        

        SET_USER_TRIPS(state, trips){
            state.userTrips = trips;
        },

        SET_TODAY_USER_TRIPS(state, trips){
            state.userTodayTrips = trips;
        },


        SET_VEHICLES_STATS(state, stats){
            state.vehiclesStats = stats;
        },

        SET_VEHICLES_FUEL_STATS(state, stats){
            state.vehiclesFuelStats = stats;
        },


        SET_ABSENCE_REQUESTS(state, absences){
            state.absenceRequests = absences;
        },

        SET_SERVICE_ITEM_NAMES(state, names) {
            state.serviceItemNames = names;
        },

        SET_WORK_ATTENDANCE_EMPLOYEES(state, employees) {
            state.workAttendanceEmployees = employees
        },


        SET_TOOL_TYPES(state, toolTypes) {
            state.toolTypes = toolTypes
        },

        SET_WAREHOUSES(state, warehouses) {
            state.warehouses = warehouses
        },

        SET_ALL_ACTIVE_USERS(state, users) {
            state.allActiveUsers = users
        },

        SET_TOOLS_NAMES(state, names) {
            state.toolsNames = names
        },
        SET_TOOLS_SUBTYPES_NAMES(state, names) {
            state.toolsSubtypesNames = names
        },

        SET_AWAITING_TOOL_TRANSFERS(state, toolTransfers) {
            state.awaitingToolTransfers = toolTransfers
        },

        SET_TOOL_PROFILE(state, tool) {
            state.toolProfile = tool
        },

        SET_CATERING_SERVICES(state, cateringServices) {
            state.allCateringServices = cateringServices
        },

        SET_SETTINGS(state, settings) {
            state.settings = settings
        },


    },

    actions: {

        loadAuthorizedUser({commit}) {
            if(!localStorage.getItem('token'))
                return;

            let token = localStorage.getItem('token').substring(1, localStorage.getItem('token').length-1);
            AuthenticationService.getAuthorizedUser(token)
                .then(response => { commit('SET_USER', response.data); })
                .catch(error => { console.log(error); })
        },

        setLoggedInUser({commit}, data) {
            commit('SET_USER', data);
        },

        loadAllWorkers({commit}) {
            EmployeesService.getAllWorkers()
                .then(response => {
                    commit('SET_ALL_WORKERS', response.data);
                })
                .catch(error => {
                    console.log(error);
                })
        },

        async loadAllWorkOrders({commit}) {
            const response = await WorkOrderService.getAllWorkOrders();
            commit('SET_WORK_ORDERS', response.data);
            return response.data
        },

        async loadWorkPositions({commit}, data) {
            await WorkOrderService.getWorkOrderPositionGroupsTree(data.workOrderId)
                .then(response => {
                    commit('SET_POSITION_GROUPS', response.data);
                })
                .catch(error => {
                    console.log(error);
                })
        },

        async loadWorkLocations({commit}, data) {
            const result = await WorkOrderService.getWorkOrderLocationsTree(data.workOrderId)
            commit('SET_WORK_LOCATIONS', result.data);
        },

        
        async loadAssignableWorkLocations({commit}, data) {
            await WorkPositionService.getWorkLocationsByPosition(data.positionId)
                .then(response => {
                    commit('SET_ASSIGNABLE_WORK_LOCATION', response.data);
                })
                .catch(error => {
                    console.log(error);
                })
        },
        
        loadWorkLocationPositions({commit}, data) {
            WorkLocation.getTreeWorkLocationPositions(data.workLocationId)
                .then(response => {
                    commit('SET_WORK_LOCATION_POSITION', response.data);
                })
                .catch(error => {
                    console.log(error);
                })
        },

        addJobAssignment({commit}, data) {
            commit('SET_JOB_ASSIGNMENT_SENDING');

            JobAssignmentService.addWorkAssignment(data.assignment)
                .then(() => {
                    commit('SET_JOB_ASSIGNMENT_SENT');
                })
                .catch(error => {
                    console.log(error);

                    commit('SET_JOB_ASSIGNMENT_ERROR', error.response);
                })
        },

        loadWorkerJobs({commit}, data) {
            commit('SET_WORKER_JOBS', []);

            JobAssignmentService.getWorkerJobs(data.workerId)
                .then(response => {
                    commit('SET_WORKER_JOBS', response.data);
                })
                .catch(error => {
                    console.log(error);
                    commit('CLEAR_WORKER_JOBS');

                });
        },

        async loadPositionGroups({commit}, data) {
            let response = await WorkPositionService.getPositionGroupsByWorkOrder(data.workOrderId)
                .catch(error => {
                    console.log(error);
                });
            commit('SET_POSITION_GROUPS', response.data);
            return response.data

        },

        async updateWorkLocations({commit}, data) {
            const response = await WorkOrderService.updateWorkLocationsInWorkOrder(data)
            commit('SET_WORK_LOCATIONS', response.data);

        },

        async updateWorkPositions({commit}, data) {
            let response = await WorkPositionService.updateWorkPositionsInWorkOrder(data)
            commit('SET_WORK_POSITIONS', response.data);
        },

        getPositionUnits({commit}) {
            WorkPositionService.getPositionUnits()
                .then(response => {
                    commit('SET_POSITION_UNITS', response.data);
                })
                .catch(error => {
                    console.log(error);
                })
        },

        

        async loadPositionUnitsIfUndefined({commit, state}) {
            if (Object.keys(state.position_units).length > 0) return;
            await WorkPositionService.getPositionUnits()
                .then(response => {
                    commit('SET_POSITION_UNITS', response.data);
                })
                .catch(error => {
                    console.log(error);
                })
        },

        getWorkOrder({commit, state}, data) {
            if (!data.force && state.workOrder?.id === data.id) return;

            commit('SET_WORK_ORDER', null);
            commit('SET_WORK_ORDER_STATUS', "SENDING");

            WorkOrderService.getWorkOrder(data.id)
                .then(response => {
                    commit('SET_WORK_ORDER', response.data);
                    commit('SET_WORK_ORDER_STATUS', "SUCCESS");
                })
                .catch(error => {

                    commit('SET_WORK_ORDER_STATUS', "ERROR");
                    commit('SET_WORK_ORDER_MESSAGE', error?.message);

                    console.log(error);
                })
        },

        async createWorkOrder({commit}, data) {
            commit('SET_WORK_ORDER', null);
            commit('SET_WORK_ORDER_STATUS', "SENDING");

            WorkOrderService.createWorkOrder(data)
                .then(response => {
                    commit('SET_WORK_ORDER', response.data);
                    commit('SET_WORK_ORDER_STATUS', "SUCCESS");
                })
                .catch(error => {

                    commit('SET_WORK_ORDER_STATUS', "ERROR");
                    commit('SET_WORK_ORDER_MESSAGE', error?.message);

                    console.log(error);
                })
        },

        async updateWorkOrder({commit}, data) {
            commit('SET_WORK_ORDER', null);
            commit('SET_WORK_ORDER_STATUS', "SENDING");

            WorkOrderService.updateWorkOrder(data)
                .then(response => {
                    commit('SET_WORK_ORDER', response.data);
                    commit('SET_WORK_ORDER_STATUS', "SUCCESS");

                    this.$toasted.show('Uspešno ažuriran nalog');
                })
                .catch(error => {

                    commit('SET_WORK_ORDER_STATUS', "ERROR");
                    commit('SET_WORK_ORDER_MESSAGE', error?.message);
                });
        },


        async loadWorkOrderTypes({commit}, data) {
            commit('SET_LOCATION_TYPES', {});
            commit('SET_LOCATION_TYPES_LOADING');

            await TypeService.getWorkOrderTypes(data.workOrderId)
                .then(response => {
                    commit('SET_LOCATION_TYPES', response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        },

        
        async updateWorkOrderTypes({commit}, data) {
            commit('SET_LOCATION_TYPES', {});
            commit('SET_LOCATION_TYPES_LOADING', "SENDING");


            await TypeService.updateTypesInWorkOrder(data)
                .then(response => {
                    commit('SET_LOCATION_TYPES', response.data);
                })
                .catch(error => {
                    commit('SET_LOCATION_TYPES_LOADING', "ERROR");
                    commit('SET_LOCATION_TYPES_MESSAGE', error.data);

                    console.log(error);
                });
        },


        async updateWorksInJob({commit}, data) {
            commit('SET_WORKS_IN_JOB', {});
            commit('SET_WORKS_IN_JOB_STATUS', "SENDING");

            await JobAssignmentService.updateWorksInJob(data)
                .then(response => {
                    commit('SET_WORKS_IN_JOB', response);
                    commit('SET_WORKS_IN_JOB_STATUS', "SUCCESS");


                })
                .catch(error => {
                    commit('SET_WORKS_IN_JOB_MESSAGE', error);
                    commit('SET_WORKS_IN_JOB_STATUS', "ERROR");

                    console.log(error);
                });
        },


        updateWorksInJobReview({commit}, data) {
            commit('SET_WORKS_IN_JOB', {});
            commit('SET_WORKS_IN_JOB_STATUS', "SENDING");

            JobAssignmentService.updateWorksInJobReview(data)
                .then(response => {
                    commit('SET_WORKS_IN_JOB', response);
                    commit('SET_WORKS_IN_JOB_STATUS', "SUCCESS");


                })
                .catch(error => {
                    commit('SET_WORKS_IN_JOB_MESSAGE', error);
                    commit('SET_WORKS_IN_JOB_STATUS', "ERROR");

                    console.log(error);
                });
        },

        resetJobStatus({commit}) {
            commit('SET_WORKS_IN_JOB_STATUS', null);
        },


        async submitJobForReview({commit}, data) {
            await JobAssignmentService.submitForReview(data)
            .then(() => {
                commit('SET_WORKER_JOBS_STATUS', "SUCCESS");


            })
            .catch(error => {
                commit('SET_WORKER_JOBS_STATUS', "ERROR");
                console.log(error);
            });
        },

        resetWorkerJobsStatus({commit}) {
            commit('SET_WORKER_JOBS_STATUS', null);
        },


        loadAllJobs({commit}) {
            commit('CLEAR_ALL_JOBS');
            commit('SET_ALL_JOBS_STATUS', "SENDING");

            JobAssignmentService.getAllJobs()
                .then(response => {
                    commit('SET_ALL_JOBS', response.data);
                    commit('SET_ALL_JOBS_STATUS', "SUCCESS");

                })
                .catch(error => {
                    console.log(error);
                    commit('CLEAR_ALL_JOBS');
                    commit('SET_ALL_JOBS_STATUS', "ERROR");

                });
        },

        loadJobsForEmployee({commit}, id) {
            commit('CLEAR_ALL_JOBS');
            commit('SET_ALL_JOBS_STATUS', "SENDING");

            EmployeesService.getJobsForEmployee(id)
                .then(response => {
                    commit('SET_ALL_JOBS', response.data);
                    commit('SET_ALL_JOBS_STATUS', "SUCCESS");

                })
                .catch(error => {
                    console.log(error);
                    commit('CLEAR_ALL_JOBS');
                    commit('SET_ALL_JOBS_STATUS', "ERROR");

                });
        },

        getWorkOrderJobs({commit}, data) {
            commit('CLEAR_WO_ACTIVE_JOBS');
            commit('SET_WO_ACTIVE_JOBS_STATUS', "SENDING");

            WorkOrderService.getActiveJobs(data.workOrderId)
                .then(response => {
                    commit('SET_WO_ACTIVE_JOBS', response.data);
                    commit('SET_WO_ACTIVE_JOBS_STATUS', "SUCCESS");

                })
                .catch(error => {
                    console.log(error);
                    commit('SET_WO_ACTIVE_JOBS');
                    commit('SET_WO_ACTIVE_JOBS_STATUS', "ERROR");

                });
        },

        
        async getWorkPositionJobs({commit}, data) {
            //commit('CLEAR_WP_JOBS');
            commit('SET_WP_JOBS_STATUS', "SENDING");

            await WorkPositionService.getAllJobs(data.workPositionId)
                .then(response => {
                    commit('SET_WP_JOBS', response.data);
                    commit('SET_WP_JOBS_STATUS', "SUCCESS");

                })
                .catch(error => {
                    console.log(error);
                    commit('CLEAR_WP_JOBS');
                    commit('SET_WP_JOBS_STATUS', "ERROR");

                });
        },

        loadActiveJobs({commit}) {
            commit('CLEAR_ALL_JOBS');
            commit('SET_ALL_JOBS_STATUS', "SENDING");

            JobAssignmentService.getActiveJobs()
                .then(response => {
                    commit('SET_ALL_JOBS', response.data);
                    commit('SET_ALL_JOBS_STATUS', "SUCCESS");
                })
                .catch(error => {
                    console.log(error);
                    commit('CLEAR_ALL_JOBS');
                    commit('SET_ALL_JOBS_STATUS', "ERROR");

                });
        },

        async submitReview({commit}, data) {
            await JobAssignmentService.submitReview(data)
            .then(() => {
                commit('SET_ALL_JOBS_STATUS', "SUCCESS");


            })
            .catch(error => {
                commit('SET_ALL_JOBS_STATUS', "ERROR");
                console.log(error);
            });
        },

        clearWorkPosition({commit}) {
            commit('SET_POSITION_GROUPS', []);
        },
        
        clearWorkLocations({commit}) {
            commit('SET_WORK_LOCATIONS', []);
        },

        loadAllEmployees({commit}) {
            commit('SET_ALL_EMPLOYEES_STATUS', "SENDING");
            commit('SET_ALL_EMPLOYEES', []);
            
            EmployeesService.getAllEmployees()
                .then(response => {
                    commit('SET_ALL_EMPLOYEES', response.data);
                    commit('SET_ALL_EMPLOYEES_STATUS', "SUCCESS");

                })
                .catch(error => {
                    commit('SET_ALL_EMPLOYEES_MESSAGE', error?.message);
                    commit('SET_ALL_EMPLOYEES_STATUS', "ERROR");
                    console.log(error);
                })
        }, 

        async loadWorkOrderWorkers({commit}, data) {
            commit('SET_ALL_WORKERS_STATUS', "SENDING");
            commit('SET_ALL_WORKERS', []);
            
            await EmployeesService.getWorkOrderWorkers(data.workOrderId)
                .then(response => {
                    commit('SET_ALL_WORKERS', response.data);
                    commit('SET_ALL_WORKERS_STATUS', "SUCCESS");

                })
                .catch(error => {
                    commit('SET_ALL_WORKERS_MESSAGE', error?.message);
                    commit('SET_ALL_WORKERS_STATUS', "ERROR");
                    console.log(error);
                })
        },    

        loadWorkOrderEmployees({commit}, data) {
            commit('SET_WO_EMPLOYEES', []);
            commit('SET_ALL_WORKERS_STATUS', "SENDING");

            WorkOrderService.getWorkOrderEmployees(data.workOrderId)
                .then(response => {
                    commit('SET_WO_EMPLOYEES', response.data);
                    commit('SET_WO_EMPLOYEES_STATUS', "SUCCESS");

                })
                .catch(error => {
                    commit('SET_WO_EMPLOYEES_MESSAGE', error?.message);
                    commit('SET_WO_EMPLOYEES_STATUS', "ERROR");
                    console.log(error);
                })
        },

        async createWorkOrderEmployee({commit}, data) {
            commit('SET_WORK_ORDER', null);
            commit('SET_WO_EMPLOYEES_STATUS', "SENDING");

            await WorkOrderService.createWorkOrderEmployee(data)
                .then(response => {
                    commit('SET_WORK_ORDER', response.data);
                    commit('SET_WO_EMPLOYEES_STATUS', "SUCCESS");

                    this.$toasted.show('Uspešno dodat radnik');
                })
                .catch(error => {

                    commit('SET_WO_EMPLOYEES_STATUS', "ERROR");
                    commit('SET_WO_EMPLOYEES_MESSAGE', error?.message);

                    console.log(error);
                })
        },

        async updateWorkOrderEmployee({commit}, data) {
            // commit('SET_WORK_ORDER', null);
            commit('SET_WO_EMPLOYEES_STATUS', "SENDING");

            await WorkOrderService.updateWorkOrderEmployee(data)
                .then(() => {
                    // commit('SET_WORK_ORDER', response.data);
                    commit('SET_WO_EMPLOYEES_STATUS', "SUCCESS");

                    this.$toasted.show('Uspešno ažuriran radnik');
                })
                .catch(error => {

                    commit('SET_WO_EMPLOYEES_STATUS', "ERROR");
                    commit('SET_WO_EMPLOYEES_MESSAGE', error?.message);
                });
        },


        loadWorksInWorkLocation({commit}, data) {
            // commit('SET_WORK_ORDER', null);
            commit('SET_WORKS_STATUS', "SENDING");

            WorkLocationService.getWorksInWorkLocation(data)
                .then(response => {
                    commit('SET_WORKS', response.data);
                    commit('SET_WORKS_STATUS', "SUCCESS");
                })
                .catch(error => {

                    commit('SET_WORKS_STATUS', "ERROR");
                    commit('SET_WORKS_MESSAGE', error?.message);
                });
        },


        loadLocationPositionWorks({commit}, data) {
            commit('SET_LOC_POS_WORKS', null);
            commit('SET_LOC_POS_WORKS_STATUS', "SENDING");

            JobAssignmentService.getWorksInWorkLocationPosition(data)
                .then(response => {
                    commit('SET_LOC_POS_WORKS', response.data);
                    commit('SET_LOC_POS_WORKS_STATUS', "SUCCESS");
                })
                .catch(error => {

                    commit('SET_LOC_POS_WORKS_STATUS', "ERROR");
                    commit('SET_LOC_POS_WORKS_MESSAGE', error?.message);
                });
        },


        async loadWorkLocation({commit}, data) {
            commit('SET_SELECTED_WL', null);
            commit('SET_SELECTED_WL_STATUS', "SENDING");

            await WorkLocation.getWorkLocationFetchParent(data)
                .then(response => {
                    if (data.prepareParents) UtilService.prepareRecursiveLocations(response.data);

                    commit('SET_SELECTED_WL', response.data);
                    commit('SET_SELECTED_WL_STATUS', "SUCCESS");
                })
                .catch(error => {

                    commit('SET_SELECTED_WL_STATUS', "ERROR");
                    commit('SET_SELECTED_WL_MESSAGE', error?.message);
                });
        },


        loadWorkPosition({commit, state}, data) {
            if (!data.force && state.selectedWorkPosition?.id === data.workPositionId) return;      

            commit('SET_SELECTED_WP', null);
            commit('SET_SELECTED_WP_STATUS', "SENDING");

            WorkPositionService.getWorkPosition(data)
                .then(response => {
                    if (data.prepareParents) UtilService.prepareRecursivePositions(response.data);

                    commit('SET_SELECTED_WP', response.data);
                    commit('SET_SELECTED_WP_STATUS', "SUCCESS");
                })
                .catch(error => {

                    commit('SET_SELECTED_WP_STATUS', "ERROR");
                    commit('SET_SELECTED_WP_MESSAGE', error?.message);
                });
        },


        async loadNormative({commit}, data) {
            commit('SET_SELECTED_NORM', null);
            commit('SET_SELECTED_NORM_STATUS', "SENDING");

            if (data.typeId) {
                await NormativeService.getNormativeByTypeAndPosition(data)
                    .then(response => {
                        commit('SET_SELECTED_NORM', response.data);
                        commit('SET_SELECTED_NORM_STATUS', "SUCCESS");
                    })
                    .catch(error => {

                        commit('SET_SELECTED_NORM_STATUS', "ERROR");
                        commit('SET_SELECTED_NORM_MESSAGE', error?.message);
                    });
            } else {
                commit('SET_SELECTED_NORM', []);
                commit('SET_SELECTED_NORM_STATUS', "SUCCESS");
            }
        },

        enableClosePrompt({commit}, data) {
            if (data?.message)
                commit('ENABLE_CLOSE_PROMPT', data?.message);
            else
                commit('ENABLE_CLOSE_PROMPT');

        },

        disableClosePrompt({commit}) {
            commit('DISABLE_CLOSE_PROMPT');
        },


        async loadUserAssignment({commit}, data) {

            await EmployeesService.getEmployeeTimeline(data)
                .then(response => {

                    let current = Object.values(response.data)[0]
                    const date = UtilService.formatDateTime();

                    Object.values(response.data).forEach(pwo => {
                        if (pwo.date > date)
                            return;
                        
                        current = pwo;
                        
                    });

                    commit('SET_USER_ASSIGNMENT', current);
                })
                .catch(error => {
                    console.log(error);
                    commit('SET_USER_ASSIGNMENT', undefined);
                });
        },

        async loadUserWorkAttendance({commit}) {

            await EmployeesService.getSelfWorkAttendace().then(response => {
                commit('SET_USER_WORK_ATTENDANCE', response.data);
            }).catch(error => {
                console.log(error);
                commit('SET_USER_WORK_ATTENDANCE', null);
            })
        },

        loadUserSettings({commit}) {
            let userSettings = JSON.parse(localStorage.getItem('user-settings'));
            if (userSettings) {
                commit('SET_USER_SETTINGS', userSettings);
            } else {
                commit('SET_USER_SETTINGS', {});
            }
        },

        loadUserCache({commit}) {
            let userCache = JSON.parse(localStorage.getItem('user-cache'));
            if (userCache) {
                commit('SET_USER_CACHE', userCache);
            } else {
                commit('SET_USER_CACHE', {});
            }
        },

        updateUserSettings({commit}, data) {
            let path = data.path.split('.');
            let key = path.pop();


            commit('UPDATE_USER_SETTINGS', {path, key, data: data.save_data });


        },

        updateUserCache({commit}, data) {
            let path = data.path.split('.');
            let key = path.pop();


            commit('UPDATE_USER_CACHE', {path, key, data: data.save_data });


        },

        async loadAllTransportVehicles({commit}) {
            commit('SET_TRANSPORT_VEHICLES', []);
            const response = await TransportService.getAllTransportVehicles();
            commit('SET_TRANSPORT_VEHICLES', response.data);
        },

        async loadTransportVehicle({commit}, data) {
            const response = await TransportService.getTransportVehicle(data);
            commit('SET_TRANSPORT_VEHICLE', response.data);
        },

        resetVuex({commit}) {
            commit('RESET_STORE');
        },

        async createTransportVehicles({commit}, data) {
            const response = await TransportService.createTransportVehicle(data)
            commit('SET_TRANSPORT_VEHICLES', response.data);
        },

        async loadAllVehicleTrips({commit}, data) {
            const response = await TransportService.getAllVehicleTrips(data);
            commit('SET_VEHICLE_TRIPS', response.data);
        },

        async createVehicleTrip ({commit}, data) {
            const response = await TransportService.createVehicleTrip(data)
            commit('SET_VEHICLE_TRIPS', response.data);
        },

        async loadAllVehicleFuel({commit}, data) {
            const response = await TransportService.getAllVehicleFuel(data);
            commit('SET_VEHICLE_FUEL', response.data);
        },

        async createVehicleFuel ({commit}, data) {
            const response = await TransportService.createVehicleFuel(data)
            commit('SET_VEHICLE_FUEL', response.data);
        },

        async loadAllVehicleService({commit}, data) {
            commit('SET_VEHICLE_SERVICE', []);
            const response = await TransportService.getAllVehicleService(data);
            commit('SET_VEHICLE_SERVICE', response.data);
        },

        async createVehicleService ({commit}, data) {
            const response = await TransportService.createVehicleService(data)
            commit('SET_VEHICLE_SERVICE', response.data);
        },


        async loadUserTrips({commit}, data) {

            await TransportService.getUserTrips(data)
                .then(response => {
                    commit('SET_USER_TRIPS', response.data);
                })
                .catch(error => {
                    console.log(error);
                    commit('SET_USER_TRIPS', undefined);
                });
        },


        async loadTodayUserTrips({commit}, data) {

            await TransportService.getUserTrips(data)
                .then(response => {
                    commit('SET_TODAY_USER_TRIPS', response.data);
                })
                .catch(error => {
                    console.log(error);
                    commit('SET_TODAY_USER_TRIPS', undefined);
                });
        },



        async loadAllGasStations({commit}) {
            const response = await TransportService.getAllGasStations();
            commit('SET_GAS_STATION', response.data);
        },

        async loadAllVehicleStats({commit}, data) {
            const response = await TransportService.getVehiclesStats(data);

            let result = response.data.filter(x => x.vehicle?.deleted == false)
            commit('SET_VEHICLES_STATS', result);
        },

        async loadAllVehicleFuelStats({commit}, data) {
            const response = await TransportService.getVehiclesFuelStats(data);
            commit('SET_VEHICLES_FUEL_STATS', response.data);
        },


        async loadAbsenceRequests({commit}) {
            const response = await AbsencesService.getAbsencesRequests();
            commit('SET_ABSENCE_REQUESTS', response.data);
        },

        async loadServiceItemNames({commit}) {
            const response = await TransportService.getServiceItemsNames();
            commit('SET_SERVICE_ITEM_NAMES', response.data);
        },

        async getWorkOrderAttendance({commit}, data) {
            const response = await WorkOrderService.getWorkOrderAttendance(data);
            commit('SET_WORK_ATTENDANCE_EMPLOYEES', response.data);
        },

        async getAllWorkOrderAttendance({commit}, data) {
            const response = await WorkOrderService.getAllWorkOrderAttendance(data);
            commit('SET_WORK_ATTENDANCE_EMPLOYEES', response.data);
        },

        async clearWorkOrderAttendance({commit}) {
            commit('SET_WORK_ATTENDANCE_EMPLOYEES', []);
        },


        async getAllToolTypes({commit}) {
            const response = await ToolsService.getAllToolTypes();
            commit('SET_TOOL_TYPES', response.data);
        },

        async getAllWarehouses({commit}) {
            const response = await ToolsService.getAllWarehouses();
            commit('SET_WAREHOUSES', response.data);
        },

        async loadAllActiveUsers({commit}) {
            const response = await EmployeesService.getAllActiveUsers();
            commit('SET_ALL_ACTIVE_USERS', response.data);
        },


        async loadToolsNames({commit}) {
            const response = await ToolsService.getToolsNames();
            commit('SET_TOOLS_NAMES', response.data);
        },

        async loadToolsSubtypesNames({commit}) {
            const response = await ToolsService.getSubtypesNames();
            commit('SET_TOOLS_SUBTYPES_NAMES', response.data);
        },

        async loadAwaitingToolTransfers({commit}) {
            let query = {
                transfer_status: ["IN_TRANSFER", "REQUESTED"],
                transfer_participates: "ME",
            }

            const response = await ToolsService.getToolTransfer(query);

            commit('SET_AWAITING_TOOL_TRANSFERS', response.data);
        },

        async loadToolProfile({commit}, data) {
            const response = await ToolsService.getTool(data);
            commit('SET_TOOL_PROFILE', response.data);
        },

        async loadAllCateringServices({commit}) {
            const response = await CateringService.getAllCateringServices();
            commit('SET_CATERING_SERVICES', response.data);
            return response.data
        },

        async loadSettings({commit}, data) {
            const response = await SettingsService.getAllSettings(data);
            commit('SET_SETTINGS', response.data);
        },

    },
    modules: {
    }

})

export default store