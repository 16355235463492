import axios from "axios";
import config from "@/config";

const API_URL = config.apiUrl + config.apiUrlPrefix;

class AbsencesService {

    getAbsencesInRange(data) {
        return axios.get(
            `${API_URL}/absences`,
            {params: { 'start_date': data.start_date, 'end_date': data.end_date }}
        );

    }


    getAbsencesRequests() {
        return axios({
            method: 'GET',
            url: `${API_URL}/absences/request`,
        })
    }
    

}

export default new AbsencesService();