import axios from "axios";
import config from "@/config";

const API_URL = config.apiUrl + config.apiUrlPrefix;

class TransportService {

    getAllTransportVehicles() {
        return axios.get(
            `${API_URL}/transports`,
        );

    }

    createTransportVehicle(data) {

        return axios({
            method: 'POST',
            url: `${API_URL}/transports`,
            data: data
        })
    }

    getTransportVehicle(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/transports/` + data.id,
        })
    }

    deleteTransportVehicle(data) {
        return axios({
            method: 'DELETE',
            url: `${API_URL}/transports/` + data.id,
        })
    }

    updateTransportVehicle(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/transports/` + data.id,
            data: data
        })
    }

    getAllVehicleFuel(data) {
        return axios({
            method: 'GET',
            url:`${API_URL}/transports/` + data.vehicle + `/fuel`,
            params: data.params
        })
    }
    createVehicleFuel(data) {
        return axios({
            method: 'POST',
            url:`${API_URL}/transports/` + data.vehicle + `/fuel`,
            data: data,
        })
    }
    updateVehicleFuel(data) {
        return axios({
            method: 'PUT',
            url:`${API_URL}/transports/` + data.vehicle + `/fuel`,
            data: data,
        })
    }
    deleteVehicleFuel(data) {
        return axios({
            method: 'DELETE',
            url:`${API_URL}/transports/fuel/` + data.id,
            data: data,
        })
    }
    

    getAllVehicleService(data) {
        return axios({
            method: 'GET',
            url:`${API_URL}/transports/` + data.vehicle + `/service`
        })
    }
    createVehicleService(data) {
        return axios({
            method: 'POST',
            url:`${API_URL}/transports/` + data.vehicle + `/service`,
            data: data,
        })
    }
    updateVehicleService(data) {
        return axios({
            method: 'PUT',
            url:`${API_URL}/transports/` + data.vehicle + `/service`,
            data: data,
        })
    }
    deleteVehicleService(data) {
        return axios({
            method: 'DELETE',
            url:`${API_URL}/transports/service/` + data.id,
            data: data,
        })
    }


    getAllVehicleTrips(data) {
        return axios({
            method: 'GET',
            url:`${API_URL}/transports/` + data.vehicle + `/trip`
        })
    }
    createVehicleTrip(data) {
        return axios({
            method: 'POST',
            url:`${API_URL}/transports/` + data.vehicle + `/trip`,
            data: data,
        })
    }
    updateVehicleTrip(data) {
        return axios({
            method: 'PUT',
            url:`${API_URL}/transports/trip/` + data.id,
            data: data,
        })
    }
    deleteVehicleTrip(data) {
        return axios({
            method: 'DELETE',
            url:`${API_URL}/transports/trip/` + data.id,
            data: data,
        })
    }

    getUserTrips(data) {
        return axios({
            method: 'GET',
            url:`${API_URL}/transports/trips`,
            params: data,
        })

    }

    getUserTripsTimeline(data) {
        return axios({
            method: 'GET',
            url:`${API_URL}/transports/employees/trip-timeline`,
            params: data,
        })

    }

    getAllVehicleTripsTimeline(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/transports/trips/timeline`,
            params: data,
        })
    }




    getAllGasStations() {
        return axios.get(
            `${API_URL}/transports/gas-stations`,
        );
    }

    createGasStation(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/transports/gas-stations`,
            data: data
        })
    }

    updateGasStation(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/transports/gas-stations/` + data.id,
            data: data
        })
    }

    deleteGasStation(data) {
        return axios({
            method: 'DELETE',
            url: `${API_URL}/transports/gas-stations/` + data.id,
        })
    }

    getSuggestedTrips(data) {
        return axios( {
            method: 'GET',
            url: `${API_URL}/transports/trips/assign-suggest`,
            params: data
        })
    }

    getVehiclesStats(data) {
        return axios( {
            method: 'GET',
            url: `${API_URL}/transports/stats`,
            params: data
        })
    }

    getVehiclesFuelStats(data) {
        return axios( {
            method: 'GET',
            url: `${API_URL}/transports/stats/fuel`,
            params: data
        })
    }

    getServiceItemsNames() {
        return axios( {
            method: 'GET',
            url: `${API_URL}/transports/service/item-names`,
        })
    }


}

export default new TransportService();