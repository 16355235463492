<template>
  <div class="home">
    <Navbar />
    <Breadcrumbs />
    <HomeMenu />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import HomeMenu from '@/components/HomeMenu.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: { Navbar, HomeMenu, Footer }
}
</script>

<style>

ol.breadcrumb {
  padding: 10px 16px;
  background-color: #eee;
  display: block;
}

ol.breadcrumb li {
  display: inline-block;
}


</style>