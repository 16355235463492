import axios from "axios";
import config from "@/config";

const API_URL = config.apiUrl + config.apiUrlPrefix;

class CateringService {

    getCateringOrderStatusLabel(status) {
        if (status == "ORDERED") return "Poručeno";
        if (status == "IN_PROGRESS") return "Prihvaćeno u obradu";
        if (status == "DELIVERED") return "Dostavljeno";
        if (status == "CANCELED") return "Otkazano";

        return status;
    }

    getAllCaterings(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/caterings`,
            params: data
        });
    }

    getCatering(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/caterings/${data.id}`,
            params: data
        });
    }

    addCatering(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/caterings`,
            data: data,
        })
    }

    putCatering(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/caterings/${data.id}`,
            data: data,
        })
    }

    deleteCatering(id) {
        return axios({
            method: 'DELETE',
            url: `${API_URL}/caterings/${id}`,
        })
    }


    getAllCateringServices(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/catering-services`,
            params: data
        });
    }

    getCateringService(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/catering-services/${data.id}`,
            params: data
        });
    }

    addCateringService(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/catering-services`,
            data: data,
        })
    }

    putCateringService(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/catering-services/${data.id}`,
            data: data,
        })
    }


    getCurrentWorkOrderCateringService(payload) {
        return axios({
            method: 'GET',
            url: `${API_URL}/catering-services/current-work-order`,
            params: payload?.params
        });
    }


    getPlannedCateringOrder(payload) {
        return axios({
            method: 'GET',
            url: `${API_URL}/caterings/planned-order`,
            params: payload.params
        });
    }


    // Catering Order
    getAllCateringOrders(payload) {
        return axios({
            method: 'GET',
            url: `${API_URL}/caterings/catering-orders`,
            params: payload?.params
        });
    }

    getCateringOrder(payload) {
        return axios({
            method: 'GET',
            url: `${API_URL}/caterings/catering-orders/${payload.url.id}`,
            params: payload?.params,
            headers: payload?.headers
        });
    }

    addCateringOrder(payload) {
        return axios({
            method: 'POST',
            url: `${API_URL}/caterings/catering-orders`,
            data: payload?.data,
        })
    }

    putCateringOrder(payload) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/caterings/catering-orders/${payload.url.id}`,
            data: payload?.data,
        })
    }


    putCateringOrderInProgress(payload) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/caterings/catering-orders/${payload.url.id}/in-progress`,
            headers: payload?.headers
        })
    }


    putCateringOrderDelivered(payload) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/caterings/catering-orders/${payload.url.id}/delivered`,
            headers: payload?.headers
        })
    }

    putCateringOrderCanceled(payload) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/caterings/catering-orders/${payload.url.id}/cancel`,
        })
    }

    getAllCateringServicesStats(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/catering-services/statistics`,
            params: data
        });
    }

}

export default new CateringService();