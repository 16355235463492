import axios from "axios";
import config from "@/config";

const API_URL = config.apiUrl + config.apiUrlPrefix;

class AwsService {

    
    async uploadFile(file) {
        let result = await axios({
            method: 'post',
            url: `${API_URL}/storage/s3`,
            data: {name: file.name}
        })

        let presignedUrl = result?.data?.url

        await axios({
            method: 'put',
            url: presignedUrl,
            data: file,
            transformRequest: (data, headers) => {
                delete headers.common['Authorization'];
                return data;
            }
        })

        return presignedUrl.split('?')[0]
    }

    getSignedStorageUrl(filename) {
        return axios({
            method: 'get',
            url: `${API_URL}/storage/s3`,
            data: {name: filename}
        })
    }

    uploadToS3(data, file) {
        return axios.put(data.url, file)
    }

}

export default new AwsService();