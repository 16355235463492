import axios from "axios";
import config from "@/config";

const API_URL = config.apiUrl + config.apiUrlPrefix;


class JobAssignmentService {

    postWorkAssignment(payload) {
        return axios({
            method: 'post',
            url: `${API_URL}/job-assignments`,
            data: payload.data
        })
    }

    getJobAssignments(payload) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/` + payload.url.employeeId + `/job-assignments`,
            data: payload.data
        })
    }

    getWorkerJobs(workerId) {
        return axios.get(`${API_URL}/job-assignments/user/` + workerId + `/jobs`);
    }

    updateWorksInJob(jobData) {
        return axios({
            method: 'post',
            url: `${API_URL}/job-assignments/` + jobData.jobAssignmentId + `/works`,
            data: jobData.works
        })
    }

    updateJobWork (payload) {
        return axios({
            method: 'post',
            url: `${API_URL}/job-assignments/` + payload.url.jobAssignmentId + `/works/` + payload.url.workId,
            data: payload.data
        })
    }

    updateWorksInJobReview(jobData) {
        return axios({
            method: 'post',
            url: `${API_URL}/job-assignments/` + jobData.jobAssignmentId + `/works/review`,
            data: jobData.works
        })
    }



    submitForReview(jobData) {
        return axios({
            method: 'post',
            url: `${API_URL}/job-assignments/` + jobData.jobAssignmentId + `/submit-for-review`,
        })
    }

    submitReview(jobData) {
        return axios({
            method: 'post',
            url: `${API_URL}/job-assignments/` + jobData.jobAssignmentId + `/submit-review`,
        })
    }


    getAllJobs() {
        return axios.get(`${API_URL}/job-assignments`);
    }

    getActiveJobs() {
        return axios.get(`${API_URL}/job-assignments/active-jobs`);
    }

    getWorksInWorkLocationPosition(data){
        return axios.get(`${API_URL}/job-assignments/work-location/` + data.workLocationId + `/work-position/` + data.workPositionId + `/works`);
    }
    
    getWorksInWorkPositions(data){
        return axios.get(
            `${API_URL}/job-assignments/works/work-positions`,
            { params:{ 'id': data.workPositions.join(',') } },
            );
    }

 

    // DEPRICATED
    setJobReady (jobFormData) {
        return axios({
            method: 'post',
            url: `${API_URL}/job-assignments/ready`,
            data: jobFormData
        })
    }

    setworkOrderTaskReady(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/work-orders/` + data.url.workOrderId + `/tasks/`  + data.url.taskId + `/set-ready`
        })
    }

}

export default new JobAssignmentService();