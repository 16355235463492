import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueBreadcrumbs from 'vue-2-breadcrumbs';
import VueNestable from 'vue-nestable'
import Vuelidate from 'vuelidate'
import Toasted from 'vue-toasted';
 
import store from './store/index'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { dom } from '@fortawesome/fontawesome-svg-core'

import { GridPlugin } from "@syncfusion/ej2-vue-grids";
import { TreeGridPlugin } from '@syncfusion/ej2-vue-treegrid';
import { SchedulePlugin } from "@syncfusion/ej2-vue-schedule";
import { TooltipPlugin } from "@syncfusion/ej2-vue-popups";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { PivotViewPlugin } from '@syncfusion/ej2-vue-pivotview';
import { DropDownListPlugin} from "@syncfusion/ej2-vue-dropdowns";

import VCalendar from 'v-calendar';
// import { VueUploadComponent } from 'vue-upload-component'
import VueSilentbox from 'vue-silentbox'
import VModal from 'vue-js-modal'


dom.watch()

// import $ from 'jquery'

// var $ = require('jquery')
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'

import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css";
import { DatePickerPlugin } from '@syncfusion/ej2-vue-calendars';
import { L10n, setCulture } from '@syncfusion/ej2-base';

library.add(fas)


const VueUploadComponent = require('vue-upload-component')

Vue.component('file-upload', VueUploadComponent)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', vSelect)

Vue.use(VueBreadcrumbs);
Vue.use(VueNestable);
Vue.use(Vuelidate);
Vue.use(Toasted, { 
        theme: "toasted-primary", 
        position: "top-center", 
        duration : 5000
    });

Vue.use(GridPlugin);
Vue.use(DatePickerPlugin);
Vue.use(TreeGridPlugin);
Vue.use(DropDownListPlugin);
Vue.use(SchedulePlugin);
Vue.use(TooltipPlugin);
Vue.use(PivotViewPlugin);
Vue.use(DateRangePickerPlugin);

Vue.use(VCalendar, {
    componentPrefix: 'v',  // Use <vc-calendar /> instead of <v-calendar />
});

Vue.use(VueSilentbox)
Vue.use(VModal)

setCulture('en-US');
L10n.load({
    'en-US': {
        'pivotview': {
            'grandTotal': 'Ukupno',
            'total': 'Ukupno',
            'value': 'Vrednost',
            'noValue': 'Nema podatka',
            'row': 'Red',
            'column': 'Kolona',
            'collapse': 'Skupi',
            'expand': 'Proširi'
        },
        "pivotfieldlist": {
            'fieldList': 'Sva polja',
            'dropRowPrompt': 'Stavite red ovde',
            'dropColPrompt': 'Stavite kolonu ovde',
            'dropValPrompt': 'Stavite vrednost ovde',
            'dropFilterPrompt': 'Stavite filter ovde',
            'addPrompt': 'Dodaj polje',
            'centerHeader': 'Prevucite polja ispod',
            'add': 'Dodaj',
            'drag': 'Prevuci',
            'filters': 'Filter',
            'rows': 'Redovi',
            'columns': 'Kolone',
            'values': 'Vrednosti',
            'error': 'Greška',
            'dropAction': 'Računska polja mogu biti samo u "Vrednost" sekciji',
            'search': 'Pretraga',
            'close': 'Zatvori',
            'cancel': 'Otkaži',
            'delete': 'Obriši',
            'alert': 'Upozorenje',
            'warning': 'Upozorenje',
            'ok': 'OK',
            'allFields': 'Sva polja',
            'noMatches': 'Nema poklapanja'
        },
        'grid': {
            'EmptyRecord': 'Nema podataka',
            'UnGroup': 'Ukloni grupu',
            'EmptyDataSourceError': 'Greska, neuspešno čitanje podataka',
            'Item': 'Stavka',
            'Items': 'Stavke',
            'Columnchooser': 'Kolone',
            'SortAscending': 'Rastuće',
            'SortDescending': 'Opadajuće',
            'ClearFilter': 'Obriši filter',
            'NoResult': 'Nema rezultata',
            'NumberFilter': 'Broj Filter',
            'TextFilter': 'Tekst Filter',
            'DateFilter': 'Datum Filter',
            'MatchCase': 'Velika Mala slova',
            'ClearButton': 'Očisti',
            'StartsWith': 'Počinje sa',
            'EndsWith': 'Završava sa',
            'Contains': 'Sadrži',
            'Equal': 'Jednako',
            'NotEqual': 'Nije jednako',
            'LessThan': 'Manje od',
            'LessThanOrEqual': 'Manje i jednako',
            'GreaterThan': 'Veće od',
            'GreaterThanOrEqual': 'Veće i jednako',
            'EnterValue': 'Unesi vrednost',
            'ShowRowsWhere': 'Pokaži redove kojima',
            'CustomFilter': 'Detaljan Filter',
            'CustomFilterPlaceHolder': 'Unesi vrednost',
            'CustomFilterDatePlaceHolder': 'Unesi datum',
            'AND': 'I',
            'OR': 'ILI',
            'Blanks': 'Prazno',
            'autoFit': 'Fituj kolonu',
            'autoFitAll': 'Fituj sve kolone',
            'Search': 'Pretraga',
            'GroupDropArea': 'Prevucite kolonu ovde da bi ste je grupisali',
            'Group': 'Grupiši po koloni',
            'Ungroup': 'Ukloni grupi',
        },
        'pager': {
            'currentPageInfo': '{0} od {1} stranica',
            'totalItemsInfo': '({0} stavki)',
            'firstPageTooltip': 'Skoči na prvu stranicu',
            'lastPageTooltip': 'Skoči na poslednju stranicu',
            'nextPageTooltip': 'Sledeća stranica',
            'previousPageTooltip': 'Prethodna stranica',
            'nextPagerTooltip': 'Sledeća tabela',
            'previousPagerTooltip': 'Prethodna tabela ',
        },
        'dates': {
            'calendars': {
                'gregorian': {
                    'days': {
                        'stand-alone': {
                                'narrow': {
                                    'mon': 'pon',
                                }
                            
                        }
                    }
                }
            }
        },
        "schedule": {
            "day": "Dan",
            "week": "Nedelja",
            "workWeek": "Radna Nedelja",
            "month": "Mesec",
            "agenda": "Agenda",
            "weekAgenda": "Nedeljna Agenda",
            "workWeekAgenda": "Radna Nedelja Agenda",
            "monthAgenda": "Meseчна Agenda",
            "today": "Danas",
            "noEvents": "Nema podataka",
            "emptyContainer": "Nema podataka za danas.",
            "allDay": "Ceo dan",
            "start": "Početak",
            "end": "Kraj",
            "more": "još",
            "close": "Zatvori",
            "cancel": "Otkaži",
            "noTitle": "(Bez naslova)",
            "delete": "Izbriši",
            "deleteEvent": "Izbriši događaj",
            "deleteMultipleEvent": "Izbriši događaje",
            "selectedItems": "Selektovano",
            "deleteSeries": "Obriši seriju događaja",
            "edit": "Izmeni",
            "editSeries": "Izmeni Seriju",
            "editEvent": "Izmeni Događaj",
            "createEvent": "Kreiraj",
            "subject": "Predmet",
            "addTitle": "Dodaj Naslov",
            "moreDetails": "Još Detalja",
            "save": "Sačuvaj",
            "editContent": "Do you want to edit only this event or entire series?",
            "deleteRecurrenceContent": "Do you want to delete only this event or entire series?",
            "deleteContent": "Are you sure you want to delete this event?",
            "deleteMultipleContent": "Are you sure you want to delete the selected events?",
            "newEvent": "New Event",
            "title": "Naslov",
            "location": "Lokacija",
            "description": "Opis",
            "timezone": "Timezone",
            "startTimezone": "Start Timezone",
            "endTimezone": "End Timezone",
            "repeat": "Repeat",
            "saveButton": "Save",
            "cancelButton": "Cancel",
            "deleteButton": "Delete",
            "recurrence": "Recurrence",
            "wrongPattern": "The recurrence pattern is not valid.",
            "seriesChangeAlert": "The changes made to specific instances of this series will be cancelled and those events will match the series again.",
            "createError": "The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.",
            "recurrenceDateValidation": "Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.",
            "sameDayAlert": "Two occurrences of the same event cannot occur on the same day.",
            "editRecurrence": "Edit Recurrence",
            "repeats": "Repeats",
            "alert": "Alert",
            "startEndError": "The selected end date occurs before the start date.",
            "invalidDateError": "The entered date value is invalid.",
            "ok": "Ok",
            "occurrence": "Occurrence",
            "series": "Series",
            "previous": "Previous",
            "next": "Next",
            "timelineDay": "Dan",
            "timelineWeek": "Nedelja",
            "timelineWorkWeek": "Timeline Work Week",
            "timelineMonth": "Mesec",
            "expandAllDaySection": "Expand",
            "collapseAllDaySection": "Collapse"
        },
        "recurrenceeditor": {
            "none": "None",
            "daily": "Daily",
            "weekly": "Weekly",
            "monthly": "Monthly",
            "month": "Month",
            "yearly": "Yearly",
            "never": "Never",
            "until": "Until",
            "count": "Count",
            "first": "First",
            "second": "Second",
            "third": "Third",
            "fourth": "Fourth",
            "last": "Last",
            "repeat": "Repeat",
            "repeatEvery": "Repeat Every",
            "on": "Repeat On",
            "end": "End",
            "onDay": "Day",
            "days": "Day(s)",
            "weeks": "Week(s)",
            "months": "Month(s)",
            "years": "Year(s)",
            "every": "every",
            "summaryTimes": "time(s)",
            "summaryOn": "on",
            "summaryUntil": "until",
            "summaryRepeat": "Repeats",
            "summaryDay": "day(s)",
            "summaryWeek": "week(s)",
            "summaryMonth": "month(s)",
            "summaryYear": "year(s)"
        }

    }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
