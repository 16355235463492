import axios from "axios";
import config from "@/config";

const API_URL = config.apiUrl + config.apiUrlPrefix;

class WorkPositionService {

    getWorkPositionLabel(id) {
        return "workPosition " + id;
    }

    getWorkPosition(data) {
        return axios.get(`${API_URL}/work-positions/` + data.workPositionId);
    }

    getWorkPositionParents(data) {
        return axios.get(`${API_URL}/work-positions/` + data.workPositionId + `/parents`);
    }

    getPositionGroupsByWorkOrder(workOrderId) {
        return axios.get(`${API_URL}/work-orders/` + workOrderId + `/position-groups`);
    }

    getPositionUnits() {
        return axios.get(`${API_URL}/position-units`);
    }

    updateWorkPositionsInWorkOrder(data) {
        console.log(data.workPositions);
        return axios({
            method: 'post',
            url: `${API_URL}/work-orders/` + data.workOrderId + `/work-positions`,
            data: data.workPositions
        })
    }

    getAllJobs(id) {
        return axios.get(`${API_URL}/work-positions/` + id + `/jobs`);
    }
    

    getWorkLocationsByPosition(positionId) {
        return axios.get(`${API_URL}/work-positions/` + positionId + `/locations`);
    }

    getWorksInWorkPosition(data){
        return axios.get(`${API_URL}/work-positions/` + data.workPositionId + `/works`);
    }

    getWorkPositions(data) {
        return axios({
            method: 'get',
            url: `${API_URL}/work-orders/` + data.workOrder + `/work-positions`,
            params: data
        });
    }


}

export default new WorkPositionService();