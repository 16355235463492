<template>
    <div class="parent">
        <div class="footer">
        </div>
        <footer class="text-center">
            <div class="copyright text-center text-dark p-3">
                © 2021 Copyright
            </div>
        </footer>
    </div>
</template>

<script>
export default {


}

</script>

<style scoped>

.copyright {
    position: relative;
    bottom: 0;
    width: 100%;
    background-color: #eee;
}


</style>
