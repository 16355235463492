<template>
    <div>
        <div v-if="firstTrip">

            
            <h5 class="trip-info">
                <span v-if="isToday">Danas</span>
                <span> {{formatDate(firstTrip.start_time)}} u {{formatTime(firstTrip.start_time)}}</span>
                <span v-if="isDriver"> vozite vozilo: <strong>{{firstTrip.vehicle_obj.name}}</strong> <span class="badge bg-secondary">{{firstTrip.vehicle_obj.plate_code}}</span></span>
                <span v-else>se vozite vozilom: {{firstTrip.vehicle_obj.name}} <span class="badge bg-secondary">{{firstTrip.vehicle_obj.plate_code}}</span></span>
                <!-- <span v-if="firstTrip.end_location"> na {{firstTrip.end_location}}</span> -->
            </h5>


            <h6>Vozač: <strong>{{userFullName(firstTrip.driver_obj.user)}}</strong></h6>
            <h6>Putnici: <strong v-for="(passenger, index) in firstTrip.passengers_obj" :key="passenger.id"><template v-if="index > 0">, </template>{{userFullName(passenger.passenger)}}</strong></h6>
            
        </div>
        <h5></h5>
    </div>
</template>

<script>
    import {mapState} from 'vuex'

    import UtilService from '@/service/UtilService.js';


    export default {

        data() {
            return {

            }
        },

        computed: {
            ...mapState([
                'userTodayTrips',
                'user'
        ]),

            firstTrip() {
                return this.userTodayTrips?.[0]
            },

            isDriver() {
                if (!this.firstTrip) return false;
                return this.firstTrip.driver_obj.id === this.user.id
            },

            isToday() {
                return this.firstTrip.start_time.slice(0,10) === UtilService.getMoment().format("YYYY-MM-DD")
            }
        },

        methods: {

            userFullName : UtilService.userFullName,
            formatDateTime : UtilService.formatDateTime,
            formatTime : UtilService.formatTime,
            formatDate : UtilService.formatDate,


            loadTrips() {
                let data = {
                    'startDate': UtilService.getMoment().format("YYYY-MM-DD"),
                    'endDate': UtilService.getMoment().add(4, 'days').format("YYYY-MM-DD"),
                    'scope': 'passenger'
                };

                this.$store.dispatch('loadTodayUserTrips', data);
            },

            async loadData() {
                this.loadTrips();
            }
        },

        created() {
            this.loadData()
        },

        mounted() {
        }

    }
</script>

<style scoped>
    .trip-info small {
        font-size: 0.7em;
    }
</style>