import axios from "axios";
import config from "@/config";

const API_URL = config.apiUrl + config.apiUrlPrefix;

class WorkOrderService {

    getBreadCrumbLabel(id) {
        // For some reason breadcrumb calls this method 6x so we should get label from api and store in cache
        return "Radni Nalog " + id;
    }

    getAllWorkOrders() {
        return axios.get(`${API_URL}/work-orders`);
    }

    getWorkOrder(id) {
        return axios.get(`${API_URL}/work-orders/` + id);
    }

    getWorkOrderEmployees(id) {
        return axios.get(`${API_URL}/work-orders/` + id + `/employees`);
    }
    
    getActiveJobs(id) {
        return axios.get(`${API_URL}/work-orders/` + id + `/active-jobs`);
    }

    createWorkOrder(workOrderData) {
        return axios({
            method: 'post',
            url: `${API_URL}/work-orders`,
            data: workOrderData
        })
    }

    updateWorkOrder(workOrderData) {
        return axios({
            method: 'put',
            url: `${API_URL}/work-orders/` + workOrderData.id,
            data: workOrderData
        })
    }


    getWorkOrderPositionGroupsTree(workOrderId) {
        return axios.get(`${API_URL}/work-orders/` + workOrderId + `/work-positions`);
    }

    getWorkOrderPositions(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/work-orders/` + data.url.workOrderId + `/work-positions`,
            params: data.params
        });
    }

    getWorkOrderPositionsDetailed(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/work-orders/` + data.url.workOrderId + `/work-positions-detailed`,
            params: data.params
        });
    }

    getWorkOrderPositionsList(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/work-orders/` + data.url.workOrderId + `/work-positions/list`,
            params: data.params
        });
    }

    getWorkOrderLocationsTree(workOrderId) {
        return axios.get(`${API_URL}/work-orders/` + workOrderId + `/work-locations`);
    }


    getWorkOrderLocationsDetailed(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/work-orders/` + data.url.workOrderId + `/work-locations-detailed`,
            params: data.params
        });
    }

    getWorkOrderLocationsDetailedList(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/work-orders/` + data.url.workOrderId + `/work-locations-detailed/list`,
            params: data.params
        });
    }
    
    updateWorkLocationsInWorkOrder(data) {
        return axios({
            method: 'post',
            url: `${API_URL}/work-orders/` + data.workOrderId + `/work-locations`,
            data: data.workLocations
        })
    }


    createWorkOrderEmployee(workOrderEmployeeData) {
        return axios({
            method: 'post',
            url: `${API_URL}/work-orders/` + workOrderEmployeeData.workOrderId + `/employees`,
            data: workOrderEmployeeData
        })
    }

    updateWorkOrderEmployee(workOrderEmployeeData) {
        return axios({
            method: 'put',
            url: `${API_URL}/work-orders/` + workOrderEmployeeData.workOrderId + `/employees/` + workOrderEmployeeData.id,
            data: workOrderEmployeeData
        })
    }

    getWorkOrderAttendance(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/work-orders/` + data.work_order_id + `/work-attendance`,
            params: { 'date': data.date,  }
        })
    }


    getAllWorkOrderAttendance(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/work-orders/work-attendance`,
            params: { 'date': data.date,  }
        })
    }

    getExcludedNormatives(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/work-orders/` + data.work_order_id + `/excluded-normatives`
        })
    }

    getPWOTimeline(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/pwo/timeline`,
            params: data
        })
    }

    getPositionGroups(data) {
        return axios.get(
            `${API_URL}/work-orders/${data.work_order}/work-position-groups`,
        );
    }

    postPositionGroups(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/work-orders/${data.work_order}/work-position-groups`,
            data: data,
        })
    }

    putPositionGroups(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/work-orders/${data.work_order}/work-position-groups`,
            data: data,
        })
    }


    getWorkOrderStats(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/work-orders/stats`,
            params: data
        })
    }

    getWorkOrderTasksGrid(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/work-orders/` + data.url.workOrderId + `/tasks-grid`,
            params: data.params,
        })
    }

    /// TASK
    getWorkOrderTask(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/work-orders/` + data.url.workOrderId + `/tasks/`  + data.url.taskId
        })
    }

    getWorkOrderTaskWorks(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/work-orders/` + data.url.workOrderId + `/tasks/`  + data.url.taskId + `/works`,
            params: data.params,

        })
    }
}

export default new WorkOrderService();