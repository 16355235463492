import axios from "axios";
import config from "@/config";

const API_URL = config.apiUrl + config.apiUrlPrefix;


class NormativeService {

    getNormativeByTypeAndPosition(data) {
        return axios.get(`${API_URL}/normatives/type/` + data.typeId + `/position/` + data.workPositionId);
    }

}

export default new NormativeService();