<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light tw-none">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">
                <img alt="Logo" src="../assets/logo.png">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div v-if="user" class="collapse navbar-collapse" id="navbarSupportedContent">
                <template v-if="user.role == 'ADMIN'">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <router-link :to="{ name: 'Home' }" class="nav-link">Početna</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'EmployeesCardsPage' }" class="nav-link">Radnici</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'WorkOrders' }" class="nav-link">Radni Nalozi</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'AllPositionUnits' }" class="nav-link">Poslovne
                                jedinice</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'work-attendance' }" class="nav-link">Evidencija
                                radnika</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'JobAssignment' }" class="nav-link">Upućivanje radnika</router-link>
                        </li>
                    </ul>
                </template>

                <template v-if="user.role == 'MANAGER'">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <router-link :to="{ name: 'EmployeesCardsPage' }" class="nav-link">Radnici</router-link>
                        </li>

                        <li class="nav-item">
                            <router-link :to="{ name: 'WorkOrders' }" class="nav-link">Radni nalozi</router-link>
                        </li>

                        <li class="nav-item">
                            <router-link :to="{ name: 'JobAssignment' }" class="nav-link">Upućivanje radnika</router-link>
                        </li>

                        <li class="nav-item">
                            <router-link :to="{ name: 'JobAssignment' }" class="nav-link">Upućivanje radnika</router-link>
                        </li>
                    </ul>
                </template>

                <template v-if="user.role == 'WORKER'">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <router-link :to="{ name: 'Home' }" class="nav-link">Početna</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'EmployeeAbsences', params: { 'username': user.username } }"
                                class="nav-link">Odsustvo</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'EmployeeCarnet', params: { 'username': user.username } }"
                                class="nav-link">Karnet</router-link>
                        </li>

                        <li class="nav-item">
                            <router-link :to="{ name: 'JobAssignment' }" class="nav-link">Upućivanje radnika</router-link>
                        </li>
                        <!-- <li class="nav-item">
                    <router-link :to="{ name: 'WorkOrders'}" class="nav-link">Radni Nalozi</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'worker-jobs'}" class="nav-link">Poslovi</router-link>
                </li> -->
                    </ul>
                </template>


                <template v-if="!user">
                    <form class="form-inline my-2 my-lg-0">
                        <router-link :to="{ name: 'Login' }" class="btn btn-outline-primary">Prijavi se</router-link>
                    </form>
                </template>

                <template v-if="user">
                    <div class="nav-item">
                        <router-link :to="{ name: 'ProfilePage', params: { 'username': user.username } }"
                            class="nav-link profile">Profil</router-link>
                    </div>
                </template>

                <template v-if="user">
                    <form class="form-inline my-2 my-lg-0">
                        <a class="btn btn-outline-primary" v-on:click="logout">Odjavi se</a>
                    </form>
                </template>

            </div>
        </div>

    </nav>
</template>

<script>

import { mapState } from 'vuex'
import AuthenticationService from '@/service/AuthenticationService.js';

export default {
    name: 'Navbar',
    computed: {
        ...mapState([
            'user',
        ]),
    },

    methods: {
        logout() {
            AuthenticationService.logout();
            this.$router.push({ name: 'Login' });
        }
    }


}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
nav {
    z-index: 1;
    padding: 10px;
    background-color: white;
}

nav a {
    font-size: 120%;
}

.nav-item {
    font-weight: bold;
}

.navbar-brand img {
    height: 60px;
    float: left;
    margin-left: 30px;
}

.cart-img img {
    width: 30px;
    height: 30px;
}

.profile {
    text-align: left;
    color: rgba(0, 0, 0, .55);
}
</style>
