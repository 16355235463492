import axios from "axios";
import config from "@/config";

const API_URL = config.apiUrl + config.apiUrlPrefix;


class WorkLocationService {

    getWorkLocationLabel(id) {
        return "workLocation " + id;
    }

    getWorkLocation(data) {
        return axios.get(`${API_URL}/work-locations/` + data.workLocationId);
    }

    getWorkLocationFetchParent(data) {
        return axios.get(`${API_URL}/work-locations/` + data.workLocationId + `/parents`);
    }

    getWorkLocationPositions(wokrLocationId) {
        return axios.get(`${API_URL}/work-locations/` + wokrLocationId + `/positions`);
    }

    getTreeWorkLocationPositions(wokrLocationId) {
        return axios.get(`${API_URL}/work-locations/` + wokrLocationId + `/work-positions/tree`);
    }

    getWorksInWorkLocation(data){
        return axios.get(`${API_URL}/work-locations/` + data.workLocationId + `/works`);
    }

    postWorkLocationTasks(data) {
        return axios({
            method: 'post',
            url: `${API_URL}/work-orders/` + data.workOrderId + `/work-locations/` + data.workLocationId + `/add-tasks`,
            data: data.payload
        });
    }


}

export default new WorkLocationService();