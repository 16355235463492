import moment from "moment";

class UtilService {

    static Role = {
        Admin: 'ADMIN',
        Manager: 'MANAGER',
        Worker: 'WORKER',
    }

    static months = ["Januar", "Februar", "Mart", "April", "Maj", "Jun", "Jul", "Avgust", "Septembar", "Oktobar", "Novembar", "Decembar"]

    static workStatusLabel(status) {
        if (status == "READY") return "Spremno";
        if (status == "ON_REVIEW") return "Čeka ocenu";
        if (status == "IN_PROGRESS") return "U toku";
        if (status == "APPROVED_FINISHED") return "Završeno";
        if (status == "APPROVED_PARTIAL") return "Parcijalno";
        if (status == "REJECTED_FINISHED") return "Završeno";
        if (status == "REJECTED_PARTIAL") return "Parcijalno";
        if (status == "NOT_READY") return "Nije spremno";
        if (status == "NOT_STARTED") return "Nije započeto";

        return status;
    }

    static workLabel(work) {
        const status = UtilService.detaildWorkStatus(work);
        return UtilService.workStatusLabel(status);
    }

    static workStatusStage(work) {
        let status = UtilService.detaildWorkStatus(work);
        
        if (status == "NOT_READY") return 0;
        if (status == "READY" || status == "NOT_STARTED" || status == "APPROVED_PARTIAL" || status == "REJECTED_PARTIAL") return 1;
        if (status == "IN_PROGRESS" || status == "ON_REVIEW") return 2;
        if (status == "APPROVED_FINISHED" || status == "REJECTED_FINISHED") return 3;
        return 0;

    }

    static isStatusWorkable(work) {
        if (!work) return true;
        const status = UtilService.detaildWorkStatus(work);
        if (status == "READY") return true;
        if (status == "APPROVED_PARTIAL") return true;
        if (status == "REJECTED_PARTIAL") return true;
        if (status == "NOT_STARTED") return true;
        return false;
    }

    static detaildWorkStatus(work) {
        if (work.work_status == 'READY') return 'READY';

        if (work.work_status == 'IN_PROGRESS') {
            // TODO: Fix should be checked if submited for review
            if (work.finished_units) return 'ON_REVIEW';
            if (!work.finished_units) return 'IN_PROGRESS';
        }

        if (work.work_status == 'APPROVED') {
            if (work.finished) return 'APPROVED_FINISHED';
            else return 'APPROVED_PARTIAL';
        }

        if (work.work_status == 'REJECTED') {
            if (work.finished) return 'REJECTED_FINISHED';
            else return 'REJECTED_PARTIAL';
        }

        if (work.work_status == 'NOT_STARTED'){
            if (work.approved_units == -1) return 'NOT_READY'
            else return 'NOT_STARTED';
        }

        return work.work_status;


    }

    static workStatusClass(status) {
        if (status == "READY") return "bg-warning text-dark";
        if (status == "IN_PROGRESS") return "bg-info text-dark";
        if (status == "DONE") return "bg-info text-dark";
        if (status == "NOT_DONE") return "bg-info text-dark";
        if (status == "APPROVED") return "bg-success";
        if (status == "REJECTED") return "bg-danger";
        if (status == "NOT_SUBMITTED") return "bg-warning text-dark";
        if (status == "NOT_READY") return "bg-warning text-dark";
        if (status == "STARTED") return "bg-secondary";
        return "bg-secondary";
    }

    static workClass(work) {
        if (!work) return
        const status = UtilService.detaildWorkStatus(work);

        if (status == "READY") return "work-ready bg-warning text-dark";
        if (status == "NOT_READY") return "work-not-ready bg-info text-dark";
        if (status == "APPROVED_PARTIAL") return "approved-partial text-dark bg-green-yellow-strip";
        if (status == "APPROVED_FINISHED") return "approved-finished bg-success text-dark";
        if (status == "REJECTED_PARTIAL") return "rejected-partial bg-red-yellow-strip";
        if (status == "REJECTED_FINISHED") return "rejected-finished bg-danger";
        if (status == "NOT_STARTED") return "not-started bg-warning text-dark";
        if (status == "NOT_READY") return "not-ready bg-warning text-dark";
        return "bg-secondary";
    }

    static employeeRoleLabel(status) {
        if (status == "WORKER") return "Radnik";
        if (status == "MANAGER_I") return "Menadžer I reda";
        if (status == "MANAGER_II") return "Menadžer II reda";
        return status;
    }

    static getWeekFromNumber(number) {
        if (number == 0) return "Nedelja";
        if (number == 1) return "Ponedeljak";
        if (number == 2) return "Utorak";
        if (number == 3) return "Sreda";
        if (number == 4) return "Četvrtak";
        if (number == 5) return "Petak";
        if (number == 6) return "Subota";
        return ""
    }

    static isMobile() {

        console.log(navigator.userAgent);
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            return true;
        }
        return false;
    }


    // Dates
    static getMoment() {
        return moment();
    }

    static convertMoment(time, format=undefined) {
        if (format)
            return moment(time, format);
        else
            return moment(time);
    }

    static formatDate(date) {
        if (!(date instanceof moment)) date = moment(date);
        return date.format('DD.MM.YYYY.');
    }
    
    static formatDatePattern(date, pattern="YYYY-MM-DD") {
        if (!(date instanceof moment)) date = moment(date);
        return date.format(pattern);
    }

    static formatTime(date) {
        if (!(date instanceof moment)) date = moment(date);
        return date.format('HH:mm');
    }

    
    static formatDateTime(date) {
        if (!(date instanceof moment)) date = moment(date);
        return date.format('DD.MM.YYYY. HH:mm');
    }

    static formatDateAndOrTime(str) {
        let date = UtilService.convertMoment(str);
        if (date.format('HH:mm:ss') === "00:00:00") {
            return UtilService.formatDate(date)
        } else {
            return UtilService.formatDateTime(date)
        }

    }

    static formatLongDayMonth(date) {
        if (!(date instanceof moment)) date = moment(date);
        
        return date.date() + ". " + UtilService.months[date.month()] + " " + date.year() + ".";

    }

    static addDate(date, val, type){
        if (!(date instanceof moment)) date = moment(date);
        return date.add(val, type).format();
    
    }

    static intervalToArray(dateStart, dateEnd){
        if (dateEnd == undefined) dateEnd = moment();
        if (!(dateStart instanceof moment)) dateStart = moment(dateStart);
        if (!(dateEnd instanceof moment)) dateEnd = moment(dateEnd);
        let duration = moment.duration(dateEnd.diff(dateStart));
        return [duration.years(), duration.months(), duration.days(), duration.hours(), duration.minutes(), duration.seconds()];

    }

    static intervalToDays(dateStart, dateEnd){
        if (dateEnd == undefined) dateEnd = moment();
        if (!(dateStart instanceof moment)) dateStart = moment(dateStart);
        if (!(dateEnd instanceof moment)) dateEnd = moment(dateEnd);
        let duration = dateEnd.diff(dateStart, 'days');
        return duration;

    }

    static intervalToSeconds(dateStart, dateEnd){
        if (dateEnd == undefined) dateEnd = moment();
        if (!(dateStart instanceof moment)) dateStart = moment(dateStart);
        if (!(dateEnd instanceof moment)) dateEnd = moment(dateEnd);
        let duration = dateEnd.diff(dateStart, 'seconds');
        return duration;

    }

    static roundUpper30min(time) {
        const remainder = 30 - (time.minute() % 30);
        time = moment(time);
        time.set({second:0,millisecond:0})
        return time.add(remainder, "minutes")
    }

    static roundLower30min(time) {
        const remainder = time.minute() % 30;
        time = moment(time);
        time.set({second:0,millisecond:0})
        return time.subtract(remainder, "minutes")
    }

    static intervalArrayFullString(interval) {
        if (interval[5] > 0 && interval[4] > 0) ++interval[4];

        let result = "";
        if (interval[0] > 0 || result != "") result += interval[0] + " Godina, ";
        if (interval[1] > 0 || result != "") result += interval[1] + " Meseci, ";
        if (interval[2] > 0 || result != "") result += interval[2] + " Dana, ";
        if (interval[3] > 0 || result != "") result += interval[3] + " Sati, ";
        if (interval[4] > 0 || result != "") result += interval[4] + " Minuta, ";
        // if (interval[5] > 0 || result != "") result += interval[5] + " Sekundi ";
        return result;
    }

    static intervalArrayShortString(interval) {
        if (interval[5] > 0 && interval[4] > 0) ++interval[4];

        if (interval[0] > 0) return interval[0] + " Godina, ";
        if (interval[1] > 0) return interval[1] + " Meseci, ";
        if (interval[2] > 0) return interval[2] + " Dana, ";
        if (interval[3] > 0) return interval[3] + " Sati, ";
        if (interval[4] > 0) return interval[4] + " Minuta, ";
        if (interval[5] > 0) return interval[5] + " Sekundi ";
        return "0 Sekundi";
    }

    static intervalToDatesArray(start, end, inclusive=false) {
        const arr = [];
        const startDate = moment(start);
        const endDate = moment(end);
        const increment = inclusive ? 1 : 0;
    
        for (let dt = startDate; dt <= endDate; dt.add(1, 'day')) {
            arr.push(dt.format('YYYY-MM-DD'));
        }
    
        arr.splice(arr.length - increment, increment);
        return arr;
    }

    static formatDateWithWeek(date) {
        let result = ""
        date = moment(date);
        result += date.format("DD")
        result += " " +  UtilService.getWeekFromNumber(date.day())

        return result;
    }

    static dateToUTC(date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    }

    // Tree
    static findObjectInTree(tree, id) {
        var stack = [], node, ii;
        for (ii = 0; ii < tree['children'].length; ii += 1) {
            stack.push(tree['children'][ii]);
        }
        
        while (stack.length > 0) {
            node = stack.pop();
            if (node.id == id) {
                return node;
            } else if (node['children'] && node['children'].length) {
                for (ii = 0; ii < node['children'].length; ii += 1) {
                    stack.push(node['children'][ii]);
                }
            }
        }
        
        return null;

    }

    static insertBefore(tree, id, obj) {
        let node = this.findObjectInTree(tree, id);
        if (node == null) { tree['children'].push(obj); return}
        
        let parent = node?.parentNode
        let index = parent['children'].findIndex(x => x === node);
        parent['children'].splice(index, 0, obj);

    }
    
    static insertAfter(tree, id, obj) {
        let node = this.findObjectInTree(tree, id);
        if (node == null) { tree['children'].push(obj); return}
        
        let parent = node.parentNode
        let index = parent['children'].findIndex(x => x === node);
        index++;
        parent['children'].splice(index, 0, obj);
    }
    
    static insertInside(tree, id, obj) {
        let node = this.findObjectInTree(tree, id);
        if (node == null) { tree['children'].push(obj); return}
        
        node['children'].push(obj)
    }
    
    static removeObject(tree, id, force = false) {
        let node = this.findObjectInTree(tree, id);
        
        if (!force && node['children'].length > 0){
            return false;
        }

        let parent = node.parentNode
        let index = parent['children'].findIndex(x => x === node);
        parent['children'].splice(index, 1);
        
        return true;
    }

    static removeObjectLog(obj, force = false) {        
        if (!force && obj['children'].length > 0){
            return false;
        }

        obj.deleted = true;
        obj['children'].forEach(element => {
            this.removeObjectLog(element, true);
        });

        return true;
    }

    static restoreRemovedObject(obj) {                
        this.setDeletedParentRecursive(obj, false);
        this.setDeletedRecursive(obj,false);
    }

    static setDeletedParentRecursive(obj, value=false) {
        obj.deleted = value;

        if (obj.parentNode?.deleted) {
            this.setDeletedParentRecursive(obj.parentNode, value);
            return
        }
    }

    static setDeletedRecursive(obj, value=true){
        obj.deleted = value;

        obj['children'].forEach(element => {
            this.setDeletedRecursive(element, value);
        });
    }

    static deepClone(obj, hash = new WeakMap(), conditionalField=undefined) {
        // Do not try to clone primitives or functions
        if (Object(obj) !== obj || obj instanceof Function) return obj;
        if (hash.has(obj)) return hash.get(obj); // Cyclic reference
        try { // Try to run constructor (without arguments, as we don't know them)
            var result = new obj.constructor();
        } catch(e) { // Constructor failed, create object without running the constructor
            result = Object.create(Object.getPrototypeOf(obj));
        }
        
        if (conditionalField && obj[conditionalField] == true)
            return null;

        // Optional: support for some standard constructors (extend as desired)
        if (obj instanceof Map)
            Array.from(obj, ([key, val]) => result.set(this.deepClone(key, hash), 
            this.deepClone(val, hash)) );
        else if (obj instanceof Set)
            Array.from(obj, (key) => result.add(this.deepClone(key, hash)) );
        // Register in hash    
        hash.set(obj, result);
        // Clone and assign enumerable own properties recursively
        return Object.assign(result, ...Object.keys(obj).map (
            key => ({ [key]: this.deepClone(obj[key], hash) }) ));
    }


    // JSON
    static stringifyRecursiveObject(obj) {
        var cache = [];
        let str = JSON.stringify(obj, (key, value) => {
          if (typeof value === 'object' && value !== null) {
            // Duplicate reference found, discard key
            if (cache.includes(value)) return;

            // Store value in our collection
            cache.push(value);
          }
          return value;
        });
        cache = null;
        return str;
    }

    static isObjectsSame(obj1, obj2) {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    static listToMap(obj, keyAttribute='id'){
        return obj.reduce(function(map, obj) {
            map[obj[keyAttribute]] = obj;
            return map;
        }, {});
    }

    // Prepare tree elements
    static freeId = -1;
    static getFreePositionId() {
        return UtilService.freeId++;
    }
    static preparePositionsTree(obj, position_units, prefix, depth=0, parent=null) {
        for(let i = 0 ; i < obj.length ; i++) {
            obj[i].fullIndex = prefix + (i+1);
            obj[i].depth = depth;
            obj[i].parentNode = parent;
            obj[i].order_index = i;
    
            if (obj[i].position_unit_id) {
                obj[i].position_unit = position_units[obj[i].position_unit_id];
                obj[i].name = obj[i].position_unit?.name;
            }
            if (obj[i].id >= this.freeId) this.freeId = obj[i].id  + 1;
            if (obj[i].children?.length > 0){
                this.preparePositionsTree(obj[i].children, position_units, prefix+(i+1)+".", depth+1, obj[i]);
            }
        }
  
    }

    static prepareWorkerJobs(workerJobs) {
        workerJobs.forEach(job => {
            job.works_obj?.forEach(work => {
                this.prepareRecursivePositions(work.task_obj.work_position_obj)
                this.prepareRecursiveLocations(work.task_obj.work_location_obj);
            });

        });
    }

    static prepareRecursivePositions(obj){
        obj.parentList = this.recursivePositionsToList(obj);

    }

    static prepareRecursiveLocations(obj){
        obj.parentList = this.recursivePositionsToList(obj);
    }

    static recursivePositionsToList(obj){
        let list = [];
        let parent = obj["parent"];
        while (parent){
            list.push(parent);
            parent = parent["parent"];
        }
        return list;
    }

    static getLocationFullindex(workLocation) {
        let index = "";
        if (!workLocation?.parentList) UtilService.prepareRecursiveLocations(workLocation);
        let parents = workLocation.parentList || [];
        for (let i = parents?.length - 1; i >= 0; i--){
            index = index + "." + (parents[i].order_index + 1);
        }
        index += "." + (workLocation.order_index + 1)
        return index.substring(1);
    }

    static getPositionFullindex(workPosition) {
        let index = "";
        if (!workPosition?.parentList) UtilService.prepareRecursiveLocations(workPosition);
        let parents = workPosition.parentList || [];
        for (let i = parents.length - 1; i >= 0; i--){
            index = index + "." + (parents[i].order_index + 1);
        }
        index += "." + (workPosition.order_index + 1)
        return index.substring(1);
    }

    static fullNameList(obj) {
        if (!obj?.parentList) return

        let names = []
        let parents = obj.parentList;
        for (let i = parents.length - 1; i >= 0; i--){
            names.push(parents[i].name);
        }
        return names;
    }

    static userFullName(obj) {
        return obj.first_name + " " + obj.last_name;
    }

    static freeLocationId = -1;
    static getFreeLocationId() {
        return UtilService.freeLocationId++;
    }
    static locationsTotal = [];
    static addToLocationsTotal(depth, number) {
        if (!this.locationsTotal[depth]) UtilService.locationsTotal[depth] = 0;
        UtilService.locationsTotal[depth] += number;
    }

    static recalculateLocationFullIndex(obj, prefix="") {
        UtilService.locationsTotal = [];
        UtilService.recalculateLocationFullIndexRecursive(obj,prefix);
    }

    static removeFieldRecursive(obj, field, child="children") {
        let explore = [...obj];
        let current;
        while(explore.length != 0){
            current = explore[0];
            explore.shift();
            explore.push.apply(explore, current[child]);
            delete current[field]
        }

        return obj
    }

    static recalculateLocationFullIndexRecursive(obj, prefix="", depth=0, parent=null) {
        let totalChildren = 0;
        

        for(let i = 0 ; i < obj.length ; i++) {
            if (obj[i].deleted != true)
                UtilService.addToLocationsTotal(depth, 1);

            obj[i].fullIndex = prefix + (i+1);
            obj[i].depth = depth;
            obj[i].parentNode = parent;
            obj[i].order_index = i;

            if (obj[i].id >= UtilService.freeLocationId) UtilService.freeLocationId = obj[i].id  + 1;

            if (obj[i].children == null) obj[i].children = [];

            if (obj[i].children?.length > 0){
                let children = UtilService.recalculateLocationFullIndexRecursive(obj[i].children, prefix+(i+1)+".", depth+1, obj[i]);
                obj[i].children_no = children[0];
                obj[i].totalChildrenNo = children[1];
                totalChildren += children[1];
            } else {
                obj[i].children_no = undefined;
                obj[i].totalChildrenNo = undefined;
            }

        }
        return [obj.length, totalChildren + obj.length];
    }
    
    static position_units = {};
    static recalculatePositionFullIndex(obj, prefix="", depth=0, parent=null) {
        for(let i = 0 ; i < obj.length ; i++) {
            obj[i].fullIndex = prefix + (i+1);
            obj[i].depth = depth;
            obj[i].parentNode = parent;
            obj[i].order_index = i;

            if (obj[i].position_unit_id) {
                if (!obj[i].position_unit) obj[i].position_unit = UtilService.position_units[obj[i].position_unit_id];
                // obj[i].name = obj[i].position_unit?.name;
            }

            if (obj[i].children?.length > 0){
                UtilService.recalculatePositionFullIndex(obj[i].children, prefix+(i+1)+".", depth+1, obj[i]);
            }
        }
    }

    static getPositionTreeList(positionGroup) {

        let result = [];
        let explore = [...positionGroup];
        let current;
        while(explore.length != 0){
            current = explore[0];
            explore.shift();
            explore.push.apply(explore, current.children);
            result.push({id: current.id, name: current.name, depth: current.depth, fullIndex: current.fullIndex, position_unit:current.position_unit, position_unit_id:current.position_unit_id ,deleted: current.deleted});
        }

        if (result.length == 0) return [];
        return result.sort((a, b) => UtilService.fullIndexCompare(a.fullIndex, b.fullIndex));
    }

    static recursiveDoFunction(treeData, func, childrenAttr="children") {
        let explore = [...treeData];
        let current;
        while(explore.length != 0){
            current = explore[0];
            explore.shift();
            explore.push.apply(explore, current[childrenAttr]);

            func(current)
        }

        return treeData
    }

    static recursiveFindElement(treeData, operationFunction, childrenAttr="children", ) {
        let explore = [...treeData];
        let current;
        while(explore.length != 0){
            current = explore[0];
            explore.shift();
            explore.push.apply(explore, current[childrenAttr]);

            if (operationFunction(current)) return current
        }

        return treeData
    }

    static fullIndexCompare(a, b) {
        let aParts = a.split('.');
        let bParts = b.split('.');

        aParts = aParts.map(Number);
        bParts = bParts.map(Number);

        for (var i = 0; i < aParts.length; ++i) {
            if (bParts.length == i) {
                return 1;
            }
    
            if (aParts[i] == bParts[i]) {
                continue;
            }
            else if (aParts[i] > bParts[i]) {
                return 1;
            }
            else {
                return -1;
            }
        }
    
        if (aParts.length != bParts.length) {
            return -1;
        }
    
        return 0;

    }

    static getLocationTreeList(locationTree) {

        let result = [];
        let explore = this.deepClone(locationTree);
        
        let current;
        while(explore.length != 0){
            current = explore[0];
            explore.shift();
            explore.push.apply(explore, current.children);

            result.unshift({id: current.id, name: current.name, depth: current.depth, fullIndex: current.fullIndex, deleted: current.deleted, position_group_id: current.position_group_id, type_id: current.type_id, hide: current.hide});

        }

        if (result.length == 0) return [];

        // return result;
        return result.sort((a, b) => UtilService.fullIndexCompare(a.fullIndex, b.fullIndex));
    }

    static activateDragMove(tableId) {
        const ele = document.getElementById(tableId);
        ele.style.cursor = 'grab';

        let pos = { top: 0, left: 0, x: 0, y: 0 };

        const mouseDownHandler = function(e) {
            ele.style.cursor = 'grabbing';
            ele.style.userSelect = 'none';

            pos = {
                left: ele.scrollLeft,
                top: ele.scrollTop,
                // Get the current mouse position
                x: e.clientX,
                y: e.clientY,
            };

            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        };

        const mouseMoveHandler = function(e) {
            // How far the mouse has been moved
            const dx = e.clientX - pos.x;
            const dy = e.clientY - pos.y;

            // Scroll the element
            ele.scrollTop = pos.top - dy;
            ele.scrollLeft = pos.left - dx;
        };

        const mouseUpHandler = function() {
            ele.style.cursor = 'grab';
            ele.style.removeProperty('user-select');

            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
        };

        // Attach the handler
        ele.addEventListener('mousedown', mouseDownHandler);
    }

    static scanUnnecessaryChildrenForGroup(obj, group) {
        let unnecessary = true;
        for(let i = 0 ; i < obj.length ; i++) {
            if (obj[i].position_group_id == group) {
                unnecessary = false;
            }

            if (obj[i].children?.length > 0){
                let result = UtilService.scanUnnecessaryChildrenForGroup(obj[i].children, group);
                if (!result) unnecessary = false;
            }
        }

            obj.forEach(child => {
                if (unnecessary) child.hide = true;
                else child.hide = false;
            })

        return unnecessary;

    }

    static isWorkFinishedUser(work) {
        return (work.work_status == "IN_PROGRESS" && parseInt(work.finished_units) >= -1) || work.work_status == "NOT_STARTED";
    }

    static isWorkInProgress(work){
        if (work.work_status == "IN_PROGRESS") return true;
        return false;
    }

    static isWorkFinished(work){
        if (work.job_assignment.finished_date_time) return true;
        return false;
    }

    static isWorkDone(work){
        if (work.work_status == "APPROVED" && work.finished) return true;
        return false;
    }

    static isWorkNotReady(work){
        if (work.approved_units == -1) return true;
        return false;
    }

    static isWorkCorrectlyReviewed(work) {
        if (work.work_status == "APPROVED" || work.work_status == "REJECTED") {
            if (work.approved_units >= 1) return true;
            return false;
        }
            
        if (work.work_status == "NOT_STARTED") {
            if (work.approved_units == -1 || work.approved_units == 0) return true;
            return false;
        }
    }

    static isWorkReviewed(work){
        if (work.job_assignment.date_reviewed) return true;
        return false;
    }

    static isWorkNotReadyStatusUser(work){
        console.log(work);
        if (work.finished_units == -1) return true;
        return false;
    }

    static isWorkNotStartedUser(work){
        if (work.finished_units == 0) return true;
        return false;
    }

    static sortDates(dates) {
        return dates.sort((a, b) => UtilService.convertMoment(a).diff(UtilService.convertMoment(b)));
    }

    static translateTransportType(value) {
        if (value === 'OWN')
            return 'Sopstveni';
        else if (value === 'BUS')
            return 'Autobus';
        else if (value === 'COMPANY')
            return 'Firmin';
        else
            return '/';
    }

    static translatePayOpt(value) {
        if (value === 'CACHE')
            return 'Keš';
        else if (value === 'CARD')
            return 'Kartica';
        else
            return '/';
    }

    static translateFuelType(value) {
        if (value === 'DIESEL')
            return 'Dizel';
        else if (value === 'GASOLINE')
            return 'Benzin';
        else if (value === 'GAS')
            return 'Plin';
        else if (value === 'ELECTRICITY')
            return 'Struja';
        else
            return 'Drugo';

    }

    static translateRole(value) {
        if (value === 'ADMIN')
            return 'Admin';
        else if (value === 'MANAGER')
            return 'Menadžer';
        else if (value === 'WORKER')
            return 'Radnik';
        else
            return '/';
    }

    static translateAbsenceType(value) {
        if (value === 'VACATION')
            return 'Godišnji odmor';
        else if (value === 'SICKNESS')
            return 'Bolovanje';
        else if (value === 'RELIGIOUS')
            return 'Verski praznik';
        else if (value === 'OTHER')
            return 'Drugi razlog';
        else
            return 'drugo';
    }

    static translateAbsenceStatus(value) {
        if (value === 'APPROVED')
            return 'Odobren';
        else if (value === 'REQUESTED')
            return 'Zahteva se';
        else if (value === 'ADMIN_CHANGE')
            return 'Ponudjeno';
        else if (value === 'REJECTED')
            return 'Odbijen';
        else if (value === 'DEFAULT')
            return 'Aktivan';
        else
            return 'drugo';
    }

    static translateTransferStatus(value) {
        if (value === 'REQUESTED')
            return 'Zahteva se';
        else if (value === 'IN_TRANSFER')
            return 'Čeka potvrdu';
        else if (value === 'TRANSFERRED')
            return 'Uspešno';
        else if (value === 'DECLINED')
            return 'Odbijeno';
        else
            return '';
    }

    static translateToolDamageStatus(value) {
        if (value === 'REPORTED')
            return 'Čeka procenu';
        else if (value === 'CONFIRMED')
            return 'Potvrđeno';
        else if (value === 'REJECTED')
            return 'Odbijeno';
        else
            return '';
    }

    static absenceTypeFormat(absence, showN=true) {
        let response;
        if (absence.absence_type == "VACATION") response = "Na odmoru"
        else if (absence.absence_type == "SICKNESS") response = "Na bolovanju"
        else if (absence.absence_type == "RELIGIOUS") response = "Verski praznik"
        else response = "Na odsustvu";


        if (showN && !absence.end_date) response += " (N)"
        return response;
    }

    static getDaysBetween(date) {
        if (!date) return null;

        let convertedDate = UtilService.convertMoment(date)
        return convertedDate.diff(moment(), 'days');

    }

    static checkEmployeeWarning(employee) {
        let warnings = {}

        let application_end_date_days = UtilService.getDaysBetween(employee.application_end_date);
        let work_end_date_days = UtilService.getDaysBetween(employee.work_end_date);
        let medical_examination_date_days = UtilService.getDaysBetween(employee.medical_examination_date);
        let health_card_expiration_date_days = UtilService.getDaysBetween(employee.health_card_expiration_date);
        
        if ( application_end_date_days != null && application_end_date_days <= 30 )
            warnings["application_end_date"] = application_end_date_days;
        if ( work_end_date_days != null && work_end_date_days <= 30 )
            warnings["work_end_date"] = work_end_date_days;
        if ( medical_examination_date_days != null && medical_examination_date_days <= 30 )
            warnings["medical_examination_date"] = medical_examination_date_days;
        if ( health_card_expiration_date_days != null && health_card_expiration_date_days <= 30 )
            warnings["health_card_expiration_date"] = health_card_expiration_date_days;

        return warnings;
    }


    static checkVehicleWarning(vehicle) {
        let warnings = {}

        let registration_end_days = UtilService.getDaysBetween(vehicle.registration_end);
        
        if ( registration_end_days != null && registration_end_days <= 30 )
            warnings["registration_end"] = registration_end_days;

        return warnings;
    }

    static convertTreeGridRowsToData(data, child="children") {
        let explore = [...data];
        let current;
        while(explore.length != 0){
            current = explore[0];
            explore.shift();
            explore.push.apply(explore, current[child]);
            delete current["level"]
        }

        return data
    }

    static sumTimesheetHours(timesheet, sumOngoing=false) {
        if (!timesheet) return 0;
        let hours = 0
        timesheet.forEach(ts => {
            let endtime = ts.time_end_confirmed
            if (!ts.time_start_confirmed || !ts.time_end_confirmed) {
                if (sumOngoing) {
                    endtime = UtilService.roundLower30min(UtilService.getMoment())
                } else {
                    return
                }
            }
            let a = moment(ts.time_start_confirmed, "HH:mm:ss")
            let b = moment(endtime, "HH:mm:ss")

            let duration = moment.duration(b.diff(a)).asHours()
            if (duration > 0) hours += duration
        });

        return hours;
    }

    static getTasksStatusInfo(tasks) {
        let data = {
            "created": 0,
            "ready": 0,
            "not_ready": 0,
            "in_progress": 0,
            "done": 0,
            "total": tasks.length,
        }
        tasks.forEach(t => {
            if (t.task_status == 0) data.created += 1
            else if (t.task_status == 1) data.ready += 1
            else if (t.task_status == 2) data.not_ready += 1
            else if (t.task_status == 3) data.in_progress += 1
            else if (t.task_status == 4) data.done += 1
        })

        return data
    }

    static workPositionTasksDetailsCalc(workPositions) {
        workPositions.forEach(workPosition => {
            workPosition.tasksInfo = UtilService.getTasksStatusInfo(workPosition.tasks_obj)
        })
        return workPositions
    }

    static objectDeepAssign(target, source) {
        if (target == undefined) return source

        for (let prop in source) {
            if (typeof source[prop] === 'object'){
                if (source[prop] == null) {
                    target[prop] = source[prop]
                } else if (Array.isArray(source[prop])) {
                    target[prop] = this.objectDeepAssign(target[prop], source[prop])
                } else {
                    if (source[prop]["_explored"]) continue
                    source[prop]["_explored"] = true
                    target[prop] = this.objectDeepAssign(target[prop], source[prop])
                }
            } else {
                target[prop] = source[prop]
            }
        }

        return target
    }

    static pluralizeSR(number, forms) {
        const cases = [2, 0, 1, 1, 1, 2];
        const index = (number % 100 > 4 && number % 100 < 20) ? 2 : cases[Math.min(number % 10, 5)];
        return forms[index];
    }

    static toast(_self, message, type) {
        _self.$toasted.show(message, { 
            type: type,
            theme: "toasted-primary", 
            position: "top-center", 
            duration : 3000
        });
    }

}

export default UtilService;
