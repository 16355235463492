<template>
    <div>
        <self-sign-modal @confirm="confirmFunction" modalId="selfSignModal" />
        <h5>{{user.first_name}} {{user.last_name}}</h5>
        
        <timeline-worker-trip />

        <template v-if="currentWO && currentWO.pwo && currentWO.pwo.work_order_obj && currentWO.pwo.work_order_obj.id">
            <hr>
            <h5>Trenutno radite na <strong>{{currentWO.pwo.work_order_obj.name}}</strong></h5>
            <template v-if="userWorkAttendance">
                <template v-if="userWorkAttendance.timesheet && userWorkAttendance.timesheet[0]">
                    <h5>Evidentirani ste od <b>{{formatTimeStr(userWorkAttendance.timesheet[0].time_start_confirmed)}}</b>
                    <template v-if="userWorkAttendance.timesheet.at(-1).time_end_confirmed"> - <b>{{formatTimeStr(userWorkAttendance.timesheet.at(-1).time_end_confirmed)}}</b></template>
                    <template> 
                    ({{sumTimesheetHours(userWorkAttendance.timesheet, true)}}h)</template>
                    
                    </h5>
                    <!-- <table width="2000px" class="table timesheet-overview-table">
                        <tbody>
                            <tr>
                                <td>Radni Nalog</td>
                                <td>Početak</td>
                                <td>Kraj</td>
                            </tr>
                            <tr v-for="timesheet in userWorkAttendance.timesheet" :key="timesheet.id">
                                <td>{{timesheet.work_order_obj.name}}</td>

                                <td>{{formatTime(convertMoment(timesheet.time_start_confirmed, "HH:mm:ss"))}}</td>
                                <td>
                                    <template v-if="timesheet.time_end">
                                        {{formatTime(convertMoment(timesheet.time_end_confirmed, "HH:mm:ss"))}}
                                    </template>
                                    <template v-else>
                                        <button :disabled="loading" class="btn btn-primary" @click="selfLeave">Izlazak</button>
                                    </template>
                                </td>
                            </tr>
                            <tr col-span="3">Ukupno: {{sumTimesheetHours(userWorkAttendance.timesheet, true)}}h</tr>
                        </tbody>
                    </table> -->

                </template>

                <h5 v-else>Niste evidentirani na poslu.</h5>
                <template v-if="selfSign">
                    <div v-if="!isArrived()">
                        <button :disabled="loading" class="btn btn-primary" @click="selfAssign">
                            <template v-if="isFirstArrival()">Evidentiraj dolazak</template>
                            <template v-else>Novi dolazak</template>
                        </button>
                    </div>
                    <div v-else>
                        <button :disabled="loading" class="btn btn-primary" @click="selfAssign">Evidentiraj izlazak</button>
                    </div>

                </template>



                <h6 v-if="userWorkAttendance.manager">Poslovođa: <span>{{userFullName(userWorkAttendance.manager)}}</span></h6>

            </template>


        </template>
        <template v-else-if="pwo_list">
            <h5>Trenutno niste nigde upućeni</h5>
        </template>
   
        <hr>
        <h2>Raspored:</h2>
        <ejs-grid 
            v-if="allTimes && allTimes.length > 0"
            :dataSource="[timeline]" 
            gridLines='Both'
            class="timeline-sumarry-table"
            :allowSelection='false'
            :queryCellInfo='queryCellInfoEvent'
            :actionFailure='actionFailure'

            >
            <e-columns>

                <e-column v-for="time, timeId in allTimes" :key="timeId" v :field='time + ".status"' :headerText='time'  width='177' 
                        :headerTemplate='dateTimeHeaderTemplate' ></e-column>

            </e-columns>
        </ejs-grid>
</div>
</template>

<script>
    import {mapState} from 'vuex'

    import { TimelineMonth, TimelineViews, Agenda, Resize, DragAndDrop } from "@syncfusion/ej2-vue-schedule";
    import UtilService from '@/service/UtilService.js';
    import EmployeesService from '@/service/EmployeesService.js';
    import DateTimeEmpAssignment from '@/components/grid-templates/DateTimeEmpAssignment.vue';
    import SelfSignModal from '@/components/work_attendance/SelfSignModal.vue';
    import { Modal } from 'bootstrap';
    import TimelineWorkerTrip from '@/components/TimelineWorkerTrip.vue';
    import { fromLonLat } from "ol/proj";
    import LineString from "ol/geom/LineString";
    import {getLength} from 'ol/sphere';


// import { valueAccessor } from '@syncfusion/ej2-grids';

    export default {
        components: {TimelineWorkerTrip, SelfSignModal, },

        provide: {
            schedule: [TimelineViews, TimelineMonth, Agenda, Resize, DragAndDrop]
        },
        data() {
            return {

                current_date: UtilService.formatDatePattern(UtilService.getMoment()),
                last_date: UtilService.formatDatePattern(UtilService.getMoment().add(4,'days')),
                pwo_list: null,
                timeline: null,

                allTimes: [],
                employee_schedules: null,
                work_attendance: null,

                dateTimeHeaderTemplate() {
                    return {
                        template: DateTimeEmpAssignment
                    };
                },
                
                confirmModal: null,
                confirmMessage: "Da li ste sigurni da želite da se samostalno evidentirate?",
                longitude: null,
                latitude: null,
                leave: false,
                loading: undefined,
                attempt: 0,
            }
        },

        computed: {
            ...mapState([
                'user',
                'userAssignment',
                'userTrips',
                'userWorkAttendance',
            ]),

            currentWO() {
                if (!this.userAssignment) return undefined;
                if (this.userAssignment?.day_status?.working == false) return null
                if (this.userAssignment?.absence) return null
                return this.userAssignment;

                // let current_date = UtilService.getMoment();
                // let last = this.pwo_list[0];
                // for(let i = 1; i < this.pwo_list?.length; ++i){
                //     let new_date = UtilService.convertMoment(this.pwo_list[i].date);
                //     let last_date = UtilService.convertMoment(last.date);
                //     if (last_date <= current_date && current_date < new_date) break;
                // }

                // if (last === undefined) last = null;
                // return last;
            },

            selfSign() {
                if (this.currentWO == null) return false;

                if (this.currentWO.pwo.work_order_obj.attendance_type == "SELF_SIGN") return true;
                if (this.currentWO.pwo.work_order_obj.attendance_type == "SELF_SIGN_NO_MANAGER" && this.userWorkAttendance && this.userWorkAttendance.manager==null) return true;
                return false;

            }
        },

        methods: {

            sumTimesheetHours : UtilService.sumTimesheetHours,
            convertMoment : UtilService.convertMoment,
            formatTime : UtilService.formatTime,
            userFullName : UtilService.userFullName,

            formatTimeStr(value) {
                return this.formatTime(this.convertMoment(value, "HH:mm:ss"))
            },

            isArrived() {
                if (!(this.userWorkAttendance?.timesheet?.length > 0)) return false
                if (this.userWorkAttendance?.timesheet?.at(-1).time_end !== null) return false;
                return true
            },

            isFirstArrival() {
                return !(this.userWorkAttendance?.timesheet?.length > 0)
            },

            enableGeo() {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(() => {}, this.geoDeclined);
                } else {
                    alert("Nije moguce izvrsiti samostalnu evidencijju.");
                }
            },

            confirmFunction() {
                return
            },

            selfAssign() {
                // this.loading = true;
                // this.confimFunciton
                this.$store.dispatch('loadAllCateringServices');

                this.confirmModal.show();

                this.enableGeo();
                
            },

            selfLeave() {
                this.loading = true;
                this.leave = true;
                this.confirmMessage = "Da li ste sigurni da želite da se odjavite sa posla"
                this.confirmModal.show();
                this.enableGeo();
            },

            doSelfAttentance(position) {
                this.longitude = position.coords.longitude;
                this.latitude = position.coords.latitude;

                let [valid, radius] = this.checkLocation();

                if (valid || (radius < 250 && confirm('Evidentiraj se'))) {
                    this.selfWorkAttendanceSign(radius);
                }
                else {
                    alert("Udaljeni ste " + radius + "m od zone za samostalnu evidenciju, približite se na minimum 200m.");
                    this.attempt++;
                    this.loading = false;
                }

               
            },

            checkLocation() {
                if (this.currentWO.pwo.work_order_obj.location_longitude && this.currentWO.pwo.work_order_obj.location_latitude, this.currentWO.pwo.work_order_obj.location_radius) {
                    let lonlatCurrent = [this.longitude, this.latitude];
                    let lonlatDestination = [this.currentWO.pwo.work_order_obj.location_longitude, this.currentWO.pwo.work_order_obj.location_latitude];
                    let radius = this.distanceBetweenPoints(lonlatCurrent, lonlatDestination);
                    let radius_m = Math.round(this.realDistanceBetweenPoints(lonlatCurrent, lonlatDestination))
                    if (radius > this.currentWO.pwo.work_order_obj.location_radius) return [false, radius_m - this.currentWO.pwo.work_order_obj.location_radius];
                    return [true, 0];
                }
                return [true, 0];
            },

            distanceBetweenPoints(lonlat1, lonlat2){
                let a = fromLonLat(lonlat1)
                let b = fromLonLat(lonlat2)
                var line = new LineString([a, b]);

                return Math.round(line.getLength() * 100) / 100;
            },


            realDistanceBetweenPoints(lonlat1, lonlat2){
                let a = fromLonLat(lonlat1)
                let b = fromLonLat(lonlat2)
                var line = new LineString([a, b]);

                return getLength(line)
            },

            


            geoDeclined(error) {
                console.log(error);
                this.loading = false;
                if (error.code == error.PERMISSION_DENIED)
                    alert("NEUSPEŠNA EVIDENCIJA! Morate dozvoliti lokaciju, Kontaktirajte administratora za uputstvo");
                else if (error.code == error.POSITION_UNAVAILABLE) 
                    alert("NEUSPEŠNA EVIDENCIJA! Nema informacija o lokaciji, Kontaktirajte administratora za evidenciju");
                else
                    alert("NEUSPEŠNA EVIDENCIJA! Neuspešna evidencija, kotaktiraje administratora za evidenciju");
            },

            
            getCurrentWAObject() {
                return {
                    "id": null,
                    "date": UtilService.getMoment().format("YYYY-MM-DD"),
                    "time_start": this.getUpperRoundHour(),
                    "time_end": "19:00:00",
                    "employee_id": this.user.id,
                    "catering": false,
                    "longitude": this.longitude,
                    "latitude": this.latitude,
                    "work_order": this.currentWO.pwo.work_order,
                }
            },

            async saveWorkAttendance(work_attendance) {
                try{
                    let result = await EmployeesService.postWorkAttendance(work_attendance);
                    return result;
                } catch (error) {
                    this.$toasted.show("Neuspešno čuvanje radnika")   
                }
            },

            async selfWorkAttendanceSign(radius=0) {
                let data = {
                    "longitude": this.longitude,
                    "latitude": this.latitude,
                    "leave": this.leave,
                    "outside_distance": radius,
                }

                await EmployeesService.selfWorkAttendace(data).then((response) => {
                    console.log("Prosla prijava");
                    this.work_attendance = response.data;
                }).catch(() => {
                    console.log("Neuspesna prijava");
                })
                this.loading = false;
            },


            getUpperRoundHour() {
                return UtilService.roundUpper30min(UtilService.getMoment()).format("HH:mm")
            },

            queryCellInfoEvent(args) {
                    // console.log(args);
                    let date = args.column.headerText

                    let currentDay = args.data[date];

                    if (currentDay.absence) {
                        args.cell.classList.add('absence-day');
                        if (currentDay.absence.absence_type == 'VACATION') args.cell.classList.add('absence-vacation-day');
                        else if (currentDay.absence.absence_type == 'SICKNESS') args.cell.classList.add('absence-sickness-day');
                        else if (currentDay.absence.absence_type == 'RELIGIOUS') args.cell.classList.add('absence-religious-day');
                        else args.cell.classList.add('absence-other-day');
                    } else if (currentDay.day_status?.working === false) {
                        args.cell.classList.add('non-working-day');
                    } else if (currentDay.pwo === null || currentDay.pwo.work_order === null) {
                        args.cell.classList.add('no-work-order');
                    }



                
                // let span = args.data[args.column.headerText]?.span
                // if (span) args.colSpan = span;
                
            },

            async loadWorkerPWO(){
                let data = {
                    employee_id: this.user.id,
                    params: {
                        start_date: this.current_date,
                        end_date: this.last_date
                    }
                };
                await EmployeesService.getEmployeeTimeline(data).then(response => {
                    this.loadCurrentAssignment();
                    this.timeline = response.data
                    this.calcTimelineStatus()
                }).catch(error => {
                    console.log(error);
                })
            },

            calcTimelineStatus() {
                Object.values(this.timeline).forEach(value => {
                    value.status = "/";

                    if (value.absence) value.status = UtilService.absenceTypeFormat(value.absence)
                    else if (value.day_status && value.day_status?.working == false) value.status = "Ne radi se";
                    else if (value.pwo && value.pwo.work_order_obj) value.status = value.pwo.work_order_obj.name;
                    
                });
            },

            calculateAllTimes() {
                let times = [];
                Object.keys(this.timeline).forEach(time => {
                    times.push(time)
                });

                UtilService.sortDates(times)
                this.allTimes = times;

            },

            getAssignmentRequestData() {
                return {
                    employee_id: this.user.id,
                    params: {
                        current: true,
                    }
                };
            },

            loadCurrentAssignment() {
                this.$store.dispatch('loadUserAssignment', this.getAssignmentRequestData());
            },

            getUserWorkAttendance() {
                this.$store.dispatch('loadUserWorkAttendance');
            },

            actionFailure(args) { 
                console.log(args);  
            },


            async loadData() {
                this.getUserWorkAttendance();
                await this.loadWorkerPWO();
                this.calculateAllTimes();
            }
        },

        created() {
            this.loadData()
        },

        mounted() {
            this.confirmModal = new Modal(document.getElementById('selfSignModal'));
        }

    }
</script>

<style>
    .timeline-sumarry-table table .no-work-order {
        background: #bbb;
    }
    .timeline-sumarry-table table tr:hover .no-work-order {
        background: #aaa !important;
    }

    .timeline-sumarry-table table .absence-vacation-day {
        background: #0dff00;
    }
    .timeline-sumarry-table table tr:hover .absence-vacation-day {
        background: #0cdd01 !important;
    }


    .timeline-sumarry-table table .absence-sickness-day {
        background: #ff6449;
    }
    .timeline-sumarry-table table tr:hover .absence-sickness-day {
        background: #e9583e !important;
    }

    .timeline-sumarry-table table .absence-religious-day {
        background: #ff6449;
    }
    .timeline-sumarry-table table tr:hover .absence-religious-day {
        background: #e9583e !important;
    }

    .timeline-sumarry-table table .absence-other-day {
        background: #ffb649;
    }
    .timeline-sumarry-table table tr:hover .absence-other-day {
        background: #e9a53e !important;
    }

    .timeline-sumarry-table table .non-working-day {
        background: #bbb;
        /* color: #777777 !important; */
    }
    .timeline-sumarry-table table tr:hover .non-working-day {
        background: #aaa !important;
        /* color: #666666 !important; */
    }

</style>

<style scoped>
    .timesheet-overview-table {
        max-width: 320px;
    }
</style>