import Vue from 'vue';
import VueRouter from 'vue-router';
// import store from '@/store/index.js'
import store from '@/store/index.js';
import Home from '../views/Home.vue';
import ModalAddEditEmployee from '@/components/modals/ModalAddEditEmployee.vue';

import WorkOrderService from '@/service/WorkOrderService.js';
import WorkLocationService from '@/service/WorkLocationService.js';
import WorkPositionService from '@/service/WorkPositionService.js';
import UtilService from '@/service/UtilService.js';


Vue.use(VueRouter)

const routes = [
  {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            breadcrumb: {
                label: 'Početna'
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        },

    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/components/Login.vue')
    },
    {
        path: '/radnici',
        name: 'EmployeesCardsPage',
        component: () => import('@/views/employees/EmployeesCardsPage.vue'),
        meta: {
            breadcrumb: {
                label: "Radnici",
                parent: "Home",
            },
            authorize: [UtilService.Role.Admin]
        }
    },
    {
        path: '/radnici/osnovne-informacije',
        name: 'EmployeesInfoPage',
        component: () => import('@/views/employees/EmployeesInfoPage.vue'),
        meta: {
            breadcrumb: {
                label: "Osnovne informacije",
                parent: "EmployeesCardsPage"
            },
            authorize: [UtilService.Role.Admin]
        }
    },
    {
        path: '/radnici/karnet',
        name: 'EmployeesCarnet',
        component: () => import('@/views/employees/EmployeesCarnetPage.vue'),
        meta: {
            breadcrumb: {
                label: "Karnet",
                parent: "EmployeesCardsPage"
            },
            authorize: [UtilService.Role.Admin]
        }
    },
    {
        path: '/radnici/upucivanje-radnika',
        name: 'EmployeesAssignment',
        component: () => import('@/views/employees/EmployeesAssignmentPage.vue'),
        meta: {
            breadcrumb: {
                label: "Upućivanje radnika",
                parent: "EmployeesCardsPage"
            },
            authorize: [UtilService.Role.Admin, UtilService.Role.Manager]
        }
    },
    {
        path: '/radnici/upuceni-radnici',
        name: 'EmployeesAssignmentAndTrip',
        component: () => import('@/views/employees/EmployeesAssignmentAndTripPage.vue'),
        meta: {
            breadcrumb: {
                label: "Upućivanje radnika",
                parent: "EmployeesCardsPage"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
            // authorize: [UtilService.Role.Admin, UtilService.Role.Manager]
        }
    },
    {
        path: '/radnici/prevoz-zaposlenih',
        name: 'EmployeesTimelineTrip',
        component: () => import('@/views/employees/EmployeesTimelineTripPage.vue'),
        meta: {
            breadcrumb: {
                label: "Prevoz zaposlenih",
                parent: "EmployeesCardsPage"
            },
            authorize: [UtilService.Role.Admin]
        }
    },
    {
        path: '/radnici/prevoz-zaposlenih/organizovanje',
        name: 'EmployeesAssignmentTrip',
        component: () => import('@/views/employees/EmployeesAssignmentTripPage.vue'),
        meta: {
            breadcrumb: {
                label: "Organizovanje",
                parent: "EmployeesTimelineTrip"
            },
            authorize: [UtilService.Role.Admin]
        }
    },
    
    {
        path: '/radnici/odsustva',
        component: () => import('@/views/employees/EmployeesAbsencesPage.vue'),
        meta: {
            breadcrumb: {
                label: "Karnet",
                parent: "EmployeesCardsPage"
            },
            authorize: [UtilService.Role.Admin]
        },
        children: [
            {
                path: '',
                name: "AbsenceRequests",
                props: true,
                component: () => import('../components/employees/AbsenceRequests.vue')
            },
            {
                path: 'sva',
                name: "AllAbsences",
                props: true,
                component: () => import('../components/employees/AllAbsences.vue')
            },
        ]
    },
    {
        path: '/radnici/dodaj',
        name: 'AddEmployees',
        component: ModalAddEditEmployee,
        meta: {
            breadcrumb: {
                label: "Radnici",
                parent: "EmployeesPage"
            },
            authorize: [UtilService.Role.Admin]
        }
    },
    {
        path: '/radni-nalozi',
        name: 'WorkOrders',
        component: () => import('@/views/WorkOrdersPage.vue'),
        meta: {
            breadcrumb: {
                label: "Radni Nalozi",
                parent: "Home"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },
    {
        path: '/radni-nalozi/:woId',
        name: "WorkOrder",
        component: () => import('@/views/WorkOrderPage.vue'),
        meta: {
            breadcrumb: (pathParam) => {
                return {
                    label: WorkOrderService.getBreadCrumbLabel(pathParam.woId),
                    parent: "WorkOrders"
                }
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },
    {
        path: '/radni-nalozi/:woId/radne-lokacije',
        name: "WorkLocations",
        component: () => import('@/views/WorkLocationsPage.vue'),
        meta: {
            breadcrumb: {
                label: "Radne Lokacije",
                parent: "WorkOrder"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },
    {
        path: '/radni-nalozi/:woId/radne-lokacije/:wlId',
        name: "WorkLocation",
        component: () => import('@/views/WorkLocationPage.vue'),
        meta: {
            breadcrumb: (pathParam) => {
                return {
                    label: WorkLocationService.getWorkLocationLabel(pathParam.wlId),
                    parent: "WorkLocations"
                }
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },


    {
        path: '/radni-nalozi/:woId/pozicije',
        name: "WorkPositions",
        component: () => import('@/views/WorkPositionsPage.vue'),
        meta: {
            breadcrumb: {
                label: "Radne Pozicije",
                parent: "WorkOrder"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },
    
    {
        path: '/radni-nalozi/:woId/pozicije/:wpId',
        name: "WorkPosition",
        component: () => import('@/views/WorkPositionPage.vue'),
        meta: {
            breadcrumb: (pathParam) => {
                return {
                    label: WorkPositionService.getWorkPositionLabel(pathParam.wpId),
                    parent: "WorkPositions"
                }
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },

    {
        path: '/radni-nalozi/:woId/radnici',
        name: "WorkOrderEmployees",
        component: () => import('@/views/WorkOrderEmployeesPage.vue'),
        meta: {
            breadcrumb: {
                label: "Radnici",
                parent: "WorkOrder"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },

    {
        path: '/radni-nalozi/:woId/celokupno',
        name: "LocationPositionGrid",
        component: () => import('@/views/PositionLocationGridPage.vue'),
        meta: {
            breadcrumb: {
                label: "Lokacije-Pozicije Tabela",
                parent: "WorkOrder"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },


    {
        path: '/upucivanje-radnika',
        name: "JobAssignment",
        component: () => import('@/views/JobAssignmentPage.vue'),
        props: true,
        meta: {
            breadcrumb: {
                label: "Upucivanje radnika",
                parent: "Home"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },

    {
        path: '/profil/:username',
        name: "ProfilePage",
        component: () => import('@/views/ProfilePage.vue'),
        props: true,
        meta: {
            breadcrumb: {
                label: "Profil",
                parent: "EmployeesInfoPage"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        },
        children: [
            {
                path: 'informacije',
                name: "ProfileInfo",
                props: true,
                component: () => import('../components/profile/ProfileInfo.vue')
            },
            {
                path: 'poslovi',
                name: "EmployeeJobs",
                props: true,
                component: () => import('../components/profile/EmployeeJobs.vue')
            },
            {
                path: 'odsustva',
                name: "EmployeeAbsences",
                props: true,
                component: () => import('../components/profile/EmployeeAbsences.vue')
            },
            {
                path: 'karnet',
                name: "EmployeeCarnet",
                props: true,
                component: () => import('../components/profile/EmployeeCarnet.vue')
            },
            {
                path: 'alat',
                name: "EmployeeTools",
                props: true,
                component: () => import('../components/profile/EmployeeTools.vue')
            },
        ]
    },

    {
        path: '/profil-poslovi',
        name: "WorkerJobs",
        component: () => import('@/components/profile/EmployeeJobs.vue'),
        props: true,
        meta: {
            breadcrumb: {
                label: "Profil",
                parent: "Home"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        },
    },

    {
        path: '/aktivni-poslovi',
        name: "ActiveAssignments",
        component: () => import('@/views/ActiveJobAssignmentsPage.vue'),
        meta: {
            breadcrumb: {
                label: "Aktivni poslovi",
                parent: "Home"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },

    {
        path: '/svi-poslovi',
        name: "AllAssignments",
        component: () => import('@/views/AllJobAssignmentsPage.vue'),
        meta: {
            breadcrumb: {
                label: "Svi poslovi",
                parent: "Home"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },

    {
        path: '/poslovne-jedinice',
        name: "AllPositionUnits",
        component: () => import('@/views/AllPositionUnitsPage.vue'),
        meta: {
            breadcrumb: {
                label: "Poslovne jedinice",
                parent: "Home"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },

    {
        path: '/poslovi',
        name: "worker-jobs",
        component: () => import('@/views/WorkerJobsPage.vue'),
        meta: {
            breadcrumb: {
                label: "Radnici",
                parent: "Home"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },

    {
        path: '/evidencija-radnika',
        name: "work-attendance",
        component: () => import('@/views/WorkAttentancePage.vue'),
        meta: {
            breadcrumb: {
                label: "Evidencija radnika",
                parent: "Home"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },

    {
        path: '/radni-rani',
        name: "calendar-day-status",
        component: () => import('@/views/CalendarDayStatusPage.vue'),
        meta: {
            breadcrumb: {
                label: "Radni dani",
                parent: "Home"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },

    {
        path: '/prevoz',
        name: "transport-vehicles",
        component: () => import('@/views/TransportVehiclesPage.vue'),
        meta: {
            breadcrumb: {
                label: "Prevozi",
                parent: "Home"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },

    {
        path: '/prevoz/:tvId',
        name: "transport-vehicle",
        component: () => import('@/views/TransportVehiclePage.vue'),
        meta: {
            breadcrumb: {
                label: "Prevoz",
                parent: "transport-vehicles"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },

    {
        path: '/alat',
        name: "tools",
        component: () => import('@/views/ToolsPage.vue'),
        meta: {
            breadcrumb: {
                label: "Alat",
                parent: "Home"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        },
        children: [
            {
                path: 'moji-alati',
                name: "MyTools",
                component: () => import('../components/tools/MyTools.vue'),
                meta: {
                    breadcrumb: {
                        label: "Moji alati",
                    }
                },
            },
            {
                path: 'alati',
                name: "AllTools",
                component: () => import('../components/tools/AllTools.vue'),
                meta: {
                    breadcrumb: {
                        label: "Svi alati",
                    }
                },
            },
            {
                path: 'transferi',
                name: "ToolTransfers",
                component: () => import('../components/tools/GridToolsTransfer.vue'),
                meta: {
                    breadcrumb: {
                        label: "Razmene",
                    }
                },
            },
            {
                path: 'ostecenja',
                name: "ToolDamages",
                component: () => import('../components/tools/GridToolsDamages.vue'),
                meta: {
                    breadcrumb: {
                        label: "Oštećenja",
                    }
                },
                authorize: [UtilService.Role.Admin]
            },
            {
                path: 'servisi',
                name: "ToolServices",
                component: () => import('../components/tools/GridToolsServices.vue'),
                meta: {
                    breadcrumb: {
                        label: "Servisi",
                    }
                },
                authorize: [UtilService.Role.Admin]
            },
        ]
    },

    {
        path: '/alat/profile/:toolId',
        name: "tool-profile",
        component: () => import('@/views/ToolProfilePage.vue'),
        meta: {
            breadcrumb: {
                label: "Alat",
                parent: "tools"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        },
    },

    {
        path: '/ketering',
        name: "catering",
        component: () => import('@/views/CateringPage.vue'),
        meta: {
            breadcrumb: {
                label: "Ketering",
                parent: "Home"
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        }
    },

    {
        path: '/ket/:cateringOrderId',
        name: "ketering-view-link",
        component: () => import('@/views/catering/CateringOrderUpdatePage.vue'),
        props: true,
        meta: {
            breadcrumb: {
                label: "Ketering porudzbina",
            },
        },
    },

    {
        path: '/podesavanja',
        name: "settings",
        component: () => import('@/views/SettingsPage.vue'),
        props: true,
        meta: {
            breadcrumb: {
                label: "Podešavanje",
            },
            authorize: [UtilService.Role.Worker, UtilService.Role.Manager, UtilService.Role.Admin,]
        },
        children: [
            {
                path: 'generalno',
                name: "settings-general",
                component: () => import('../components/settings/SettingsGeneral.vue'),
                meta: {
                    breadcrumb: {
                        label: "Generalno",
                    }
                },
                authorize: [UtilService.Role.Admin]
            },
            {
                path: 'evidencija',
                name: "settings-timesheet",
                component: () => import('../components/settings/SettingsTimesheet.vue'),
                meta: {
                    breadcrumb: {
                        label: "Evidencija",
                    }
                },
                authorize: [UtilService.Role.Admin]
            },
            {
                path: 'ketering',
                name: "settings-catering",
                component: () => import('../components/settings/SettingsCatering.vue'),
                meta: {
                    breadcrumb: {
                        label: "Ketering",
                    }
                },
                authorize: [UtilService.Role.Admin]
            },
            {
                path: 'sms-servis',
                name: "settings-sms",
                component: () => import('../components/settings/SettingsSMS.vue'),
                meta: {
                    breadcrumb: {
                        label: "SMS Servis",
                    }
                },
                authorize: [UtilService.Role.Admin]
            },
        ],
    }
            
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    console.log(to);
    
    if (store.getters.preventTabClose) {
        const answer = window.confirm(store.getters.preventTabCloseMessage);
        if (!answer) {
            return;
        }
        store.dispatch('disableClosePrompt');
    }

    const { authorize } = to.meta;
    const currentUser = JSON.parse(localStorage.getItem('user'));

    if (authorize) {

        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return next({ name: 'Login' });
        }

        // check if route is restricted by role
        if (authorize.length && !authorize.includes(currentUser.role)) {
            // role not authorised so redirect to home page
            return next({ path: '/' });
        }
    }

    next();

});


export default router
