import axios from "axios";
import config from "@/config";

const API_URL = config.apiUrl + config.apiUrlPrefix;

class TypeService {

    
    getWorkOrderTypes(workOrderId) {
        return axios.get(`${API_URL}/work-orders/` + workOrderId + `/types`);
    }

    updateTypesInWorkOrder(data) {
        return axios({
            method: 'post',
            url: `${API_URL}/work-orders/` + data.workOrderId + `/types`,
            data: data.types
        })
    }

}

export default new TypeService();