<template>
    <span>{{ formatDateAndOrTime(data.headerText) }}</span>
</template>

<script>
import UtilService from '@/service/UtilService.js';

export default {
    name: 'grid-date-time-emp-assignment-template',

    data: function() {
        return {
            data: {},
        }
    },
    methods: {
        formatDatePattern: UtilService.formatDatePattern,

        formatDateAndOrTime(str) {
            let date = UtilService.convertMoment(str);
            if (date.format('HH:mm:ss') === "00:00:00") {
                return UtilService.formatDate(date)
            } else {
                return UtilService.formatDateTime(date)
            }

        }
    }
}
</script>

